export default class Settings {
    user_id = undefined;
    account_id = undefined;
    showPhone = false;
    showEmail = false;
    phone = undefined;
    email = undefined;
    name = undefined;
    note = undefined;
    sample = undefined;
    version = 1;

    constructor(settings) {
        if (this.isSet(settings))
            for (const [key, value] of Object.entries(settings))
                this.initValue(settings, key);
    }

    initValue(settings, valueName) {
        this[valueName] = this.isSet(settings[valueName])
            ? settings[valueName]
            : this[valueName];
    }

    isSet(value) {
        return !this.isNull(value) && !this.isUndefined(value);
    }

    isNull(value) {
        return value === null;
    }

    isUndefined(value) {
        return value === undefined;
    }

    isShowEmail() {
        return this.email && this.showEmail;
    }

    isShowPhone() {
        return this.phone && this.showPhone;
    }

    getSettings() {
        return {
            account_id: this.account_id,
            user_id: this.user_id,
            data: {
                showPhone: this.showPhone,
                showEmail: this.showEmail,
                phone: this.phone,
                email: this.email,
                name: this.name,
                version: this.version,
                note: this.note,
                sample: this.sample,
            },
        };
    }

    static getDefaultSettingsData() {
        return {
            showPhone: this.showPhone,
            showEmail: this.showEmail,
            note: this.note,
            sample: this.sample,
        };
    }
}
