export default class Zoho {
    static async removeZohoLauncher() {
        let isLauncherRendered = false;
        let id = "zohohc-asap-web-launcherbox";
        let count = 0;

        while (isLauncherRendered === false) {
            let element = document.getElementById(id);
            if (element !== null) {
                isLauncherRendered = true;
                element.remove();
            }

            count++;
            if (count > 10) isLauncherRendered = true;
            await new Promise((r) => setTimeout(r, 100));
        }
    }

    static async hideZoho() {
        await new Promise((r) => setTimeout(r, 500));
        let id = "zohohc-asap-web-launcherbox";
        let element = document.getElementById(id);
        if (element) element.style.visibility = "hidden";
    }

    static async displayZoho() {
        await new Promise((r) => setTimeout(r, 500));
        let id = "zohohc-asap-web-launcherbox";
        let element = document.getElementById(id);
        if (element) element.style.visibility = "visible";
    }
}
