// Packages
import React from "react";
import { computed, observable, action } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { Link } from "react-router-dom";
import { Icon, NavBar, SearchBar, Button } from "antd-mobile";
import JobCard from "../../compoents/cards/JobCard";

@inject("store")
@observer
class Jobs extends React.Component {
    @observable page = 1;
    @observable loading = false;
    @observable searchTerm = "";
    @observable showSearchBox = false;

    @computed get jobs() {
        return this.props.store.Job.jobs.filter((job) => {
            const index = job.name
                .toLowerCase()
                .search(this.searchTerm.toLowerCase());
            if (index !== -1) {
                return true;
            } else {
                return false;
            }
        });
    }

    @action.bound
    search(event) {
        this.searchTerm = event;
    }

    loadNextJobs() {
        this.loading = true;
        this.ref.current.focus();
        this.page = this.page + 1;
        this.props.store.Job.getJobs(this.props.history, this.page).then(
            (jobs) => {
                this.loading = false;
            }
        );
    }

    loadPreviousJobs() {
        this.loading = true;
        this.ref.current.focus();
        this.page = this.page - 1;
        this.props.store.Job.getJobs(this.props.history, this.page).then(
            (jobs) => {
                this.loading = false;
            }
        );
    }

    getPageNumber() {
        let page = this.props.match.params.page;

        if (Number.isNaN(page) || page === undefined) {
            page = 1;
        }

        return (this.page = page);
    }

    async componentDidMount() {
        if (this.jobs.length < 1) this.loading = true;
        this.props.store.Job.getJobs(
            this.props.history,
            this.getPageNumber()
        ).then((jobs) => {
            this.loading = false;
        });

        let account = await this.props.store.Account.getAccount(
        );

        console.log(account);
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    render() {
        return (
            <>
                <NavBar
                    mode="light"
                    onLeftClick={() =>
                        this.showSearchBox
                            ? (this.showSearchBox = false)
                            : (this.showSearchBox = true)
                    }
                    leftContent={<Icon type="search" className="clickable" />}
                >
                    <img src="/assets/logo.png" alt="GF Crew Logo" />
                </NavBar>
                <div className="container">
                    <div className="flex-center">
                        <Link to="" ref={this.ref}></Link>
                        <Link
                            to="/jobs/new"
                            className="block-button am-button am-button-warning clickable"
                        >
                            ADD JOB
                        </Link>
                    </div>
                    {this.showSearchBox && (
                        <SearchBar
                            placeholder="Search jobs..."
                            value={this.searchTerm}
                            onChange={this.search}
                            onCancel={() => (this.searchTerm = "")}
                        />
                    )}
                    {this.loading && <Icon type="loading" />}
                    {this.jobs &&
                        this.jobs.map((job) => {
                            return <JobCard job={job} />;
                        })}
                    <div className="flex-center">
                        <Button
                            onClick={this.loadPreviousJobs.bind(this)}
                            className="block-button am-button am-button-ghost clickable p-4"
                            disabled={this.page <= 1}
                        >
                            Previous Jobs
                        </Button>
                        <Button
                            onClick={this.loadNextJobs.bind(this)}
                            className="block-button am-button am-button-ghost clickable p-4"
                            disabled={this.jobs.length < 99}
                        >
                            More Jobs
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

export default Jobs;
