// Packages
import React from "react";
import { Card } from "antd-mobile";

class ChargeScan extends React.Component {
    getConfirmView() {
        return (
            <>
                <Card></Card>
            </>
        );
    }

    componentDidMount() {
        if (DyScan !== undefined) {
            let thisRef = this;

            DyScan.setApiKey(
                "ZOVTldPZc7JgJ6ZijUXPcrht5m9RvwzcFVBseyGHPkAbL03lXGl9V467u7w8"
            );
            DyScan.setScanCallback(function (json) {
                sessionStorage.setItem("dyScanJson", json.card_number);

                let dyScanView = document.getElementById("dyScanView");
                let view = document.getElementById("scanView");

                dyScanView.remove();
                view.append(thisRef.getConfirmView());
                /**
                 * @todo
                 * Look into sending getting the card info to a lamda function
                 * to generate a stripe token.
                 * That way we can cut down some loading time.
                 * Once the user hits confirm we can make the charge.
                 */

                DyScan.revealCardNumber(
                    json.card_number,
                    document.getElementById("cardInfo")
                );
            });
        }
    }
    render() {
        return (
            <>
                <div id="scanView">
                    <div id="dyScanView"></div>
                </div>
            </>
        );
    }
}
export default ChargeScan;
