// Packages
import React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { InputItem, Button, Toast, Icon } from "antd-mobile";
import { Link } from "react-router-dom";
import Zoho from "../../modules/Zoho";
// Styles
import "./SignIn.scss";

@inject("store")
@observer
class SignIn extends React.Component {
    @observable
    loading = false;

    @observable
    email = "";

    @observable
    password = "";

    @action.bound
    handleChange = (key, event) => {
        switch (key) {
            case "email":
                this.email = event;
                break;
            case "password":
                this.password = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    handleSubmit = async (e) => {
        e.preventDefault();
        this.loading = true;

        if (this.email.length <= 0 || this.password.length <= 0) {
            Toast.fail("You must enter a valid email address and password.", 2);
            this.loading = false;
            return;
        }

        const resError = await this.props.store.User.signIn(
            this.email,
            this.password
        );
        this.loading = false;
        if (resError) {
            Toast.fail(resError, 2);
        } else {
            this.props.history.push("/");
        }
    };

    componentDidMount() {
        let deferredPrompt;
        const addBtn = document.querySelector(".install-button");
        addBtn.style.display = "none";

        window.addEventListener("beforeinstallprompt", (e) => {
            console.log(e);
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // Update UI to notify the user they can add to home screen
            addBtn.style.display = "block";

            addBtn.addEventListener("click", (e) => {
                // hide our user interface that shows our A2HS button
                addBtn.style.display = "none";
                // Show the prompt
                deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === "accepted") {
                        console.log("User accepted the A2HS prompt");
                    } else {
                        console.log("User dismissed the A2HS prompt");
                    }
                    deferredPrompt = null;
                });
            });
        });
        Zoho.hideZoho();
    }

    render() {
        return (
            <div className="auth-page sign-in-page">
                <div className="auth-logo-wrapper">
                    <img
                        src="/assets/logo-white.png"
                        alt="GF Crew"
                        className="auth-logo"
                    />
                </div>

                <form onSubmit={this.handleSubmit}>
                    <InputItem
                        clear
                        type="email"
                        placeholder="Email"
                        value={this.email}
                        onChange={(event) => this.handleChange("email", event)}
                    >
                        <img src="/assets/envelope.svg" alt="envelope" />
                    </InputItem>
                    <br />
                    <InputItem
                        clear
                        type="password"
                        placeholder="Password"
                        value={this.password}
                        onChange={(event) =>
                            this.handleChange("password", event)
                        }
                    >
                        <img src="/assets/lock.svg" alt="lock" />
                    </InputItem>

                    <br />
                    <div className="forgot-password">
                        <a
                            href="https://capturelife.com/app/#/forgot_password"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Forgot Password?
                        </a>

                        <p>NOTE: Use Your CAPTURELIFE Portal Credentials</p>
                    </div>
                    <div className="buttons">
                        <Button className="am-button am-button-ghost clickable auth-ghost install-button">
                            Install App
                        </Button>
                    </div>
                    <div className="buttons">
                        <button
                            className="am-button am-button-warning clickable auth-warning"
                            type="submit"
                        >
                            {this.loading ? <Icon type="loading" /> : "SIGN IN"}
                        </button>
                    </div>
                    <div className="buttons">
                        <Link
                            to="/sign-up"
                            className="clickable"
                            style={{ color: "#fff" }}
                        >
                            PHOTOGRAPHER SIGN UP
                        </Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default SignIn;
