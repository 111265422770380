import axios from "axios";
import ZapierHooks from "./ZapierHooks";
import CaptureLifeApi from "./CaptureLifeApi";

export default class PreSignUp {
    api = null;

    constructor() {
        this.api = new CaptureLifeApi(this.getToken());
    }

    static redirectToRegister() {
        window.location = "https://register.gfcrew.com/register";
    }

    getBaseUrl() {
        return process.env.REACT_APP_API_URL;
    }
    getToken() {
        return process.env.REACT_APP_PRE_SIGNUP_CLIENT_TOKEN;
    }
    storeLocally(key, value) {
        localStorage.setItem(key, value);
    }

    getLocalData(key) {
        return localStorage.getItem(key);
    }

    clearLocalStorage() {
        localStorage.clear();
    }

    static getLogoUrl(accountId) {
        return "https://api.capturelife.com/accounts/" + accountId + "/logo";
    }

    static getAccountId() {
        return localStorage.getItem("accountId");
    }

    static setSelectedJob(job) {
        localStorage.setItem("job", job);
    }

    static getSelectedJob() {
        return JSON.parse(localStorage.getItem("job"));
    }

    static getCustomer() {
        return JSON.parse(localStorage.getItem("customer"));
    }

    static getPlayer() {
        return JSON.parse(localStorage.getItem("player"));
    }

    filterPassedStartDatesOut(job) {
        let now = new Date();
        let endDate = new Date(job.end_date);
        let yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);

        var utc_endDate = new Date(endDate.toUTCString().slice(0, -4));

        let validDate = utc_endDate.getTime() > yesterday.getTime();

        return job.pre_signup && validDate;
    }

    sortAscByEndDate(a, b) {
        let key1 = new Date(a.end_date);
        let key2 = new Date(b.end_date);

        if (key1 < key2) {
            return -1;
        } else if (key1 === key2) {
            return 0;
        } else {
            return 1;
        }
    }

    async getJobs(accountId) {
        let token = await this.getClientSideToken();
        let res = await this.api.getJobsForAccount(accountId);
        res.data.filter((data) => {
            return data.end_date;
        });
        let jobs = res.data
            .filter(this.filterPassedStartDatesOut)
            .sort(this.sortAscByEndDate);

        this.storeLocally("accountId", accountId);
        this.storeLocally("jobs", JSON.stringify(jobs));
        return jobs;
    }

    async createCustomer(jobId, customer) {
        let customerData = {
            job_id: parseInt(jobId),
            name: customer.name,
            email: customer.email,
            phone: customer.phone,
        };

        let res = await this.api.createNewCustomer(customerData);

        this.storeLocally("customer", JSON.stringify(res.data));
        return res.data;
    }

    async createPlayer(customerId, player) {
        let playerData = {
            customer_id: parseInt(customerId),
            first_name: player.first_name,
            last_name: player.last_name,
            team: player.team,
            number: player.number,
            notes: player.notes,
            position: player.position,
        };

        let res = await this.api.createNewPlayer(playerData);

        this.storeLocally("player", JSON.stringify(res.data));
        return res.data;
    }

    async createCharge(amount, token, customerId) {
        amount = Number(amount) * 100;

        try {
            let res = await axios.post(
                process.env.REACT_APP_API_URL +
                    "/portal/charges?cl_events=true",
                {
                    customer_id: parseInt(customerId),
                    amount: amount,
                    charge_token: token,
                },
                { headers: { Authorization: "Token " + this.getToken() } }
            );

            return {
                success: true,
                data: res.data,
            };
        } catch (err) {
            return {
                success: false,
                message: err.response.data.error,
            };
        }
    }

    async getClientSideToken() {
        let password = "cl1234?!";
        let email = "cl@glossyfinish.com";
        let token = "WyCiBcxK9HS9CPm0jj2TqWxbLbGs0ZbndhC2OfQ0lnxlIBQy";

        let signInRes = await axios.post(
            `https://api.capturelife.com/user/login?cl_events=true`,
            {
                email: email,
                password: password,
            }
        );
        
        token = signInRes.data.token;

        let clientSideTokenRes = await axios.get(
            "https://api.capturelife.com/cl_events/token?token=" + token
        );

        return clientSideTokenRes.data.string;
    }

    async getWebhookData(chargeId) {
        let job = this.constructor.getSelectedJob()[0];
        let settings = await this.api.getAccountSetting(job.account_id);
        let name = settings.name + " ( " + job.account_id + " )";

        return {
            name: name,
            date: job.start_date,
            Price: "" + job.default_fee / 100,
            transactionID: "" + chargeId,
        };
    }

    async recordRegisterWebHook(chargeId) {
        ZapierHooks.recordRegisterWebHook(await this.getWebhookData(chargeId));
    }
}
