// Packages
import React from "react";
import Validate from "../../modules/validator";
import { action, observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { InputItem, Button, Toast, Icon, Checkbox } from "antd-mobile";
// Styles
import "./CustomerNew.scss";

const AgreeItem = Checkbox.AgreeItem;

@inject("store")
@observer
class CustomerNew extends React.Component {
    state = {
        agreed: false,
    };
    @observable
    loading = false;

    @observable
    customerDisabled = false;

    @observable
    customer = {
        name: "",
        email: "",
        phone: "",
    };

    @observable
    player = {
        first_name: "",
        last_name: "",
        team: "",
        number: "",
        notes: "",
        position: "",
    };

    @computed get customerEntered() {
        if (
            this.customer.name.length > 0 ||
            this.customer.email.length > 0 ||
            this.customer.phone.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    @computed get playerEntered() {
        if (
            this.player.first_name.length > 0 ||
            this.player.last_name.length > 0 ||
            this.player.team.length > 0 ||
            this.player.number.length > 0 ||
            this.player.notes.length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    @computed get successMessage() {
        if (
            (!this.customerEntered || this.customerDisabled) &&
            this.playerEntered
        ) {
            return "Take a payment for your new player";
        } else {
            return "Customer created";
        }
    }

    @action.bound
    handleChange = (key, event) => {
        switch (key) {
            case "name":
                this.customer.name = event;
                break;
            case "email":
                this.customer.email = event;
                break;
            case "phone":
                this.customer.phone = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    handlePlayerChange = (key, event) => {
        switch (key) {
            case "first_name":
                this.player.first_name = event;
                break;
            case "last_name":
                this.player.last_name = event;
                break;
            case "team":
                this.player.team = event;
                break;
            case "number":
                this.player.number = event;
                break;
            case "notes":
                this.player.notes = event;
                break;
            case "position":
                this.player.position = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    async createCustomerAndOrPlayer() {
        // Create customer and/or player if forms edited
        let customerResError;
        let playerResError;
        if (this.customerEntered && !this.customerDisabled)
            customerResError = await this.props.store.Customer.createCustomer(
                this.customer
            );
        if (this.playerEntered)
            this.props.store.Customer.playerToCreate = this.player;

        this.loading = false;

        // Handle errors, process success state
        if (!this.customerEntered && !this.playerEntered) {
            Toast.fail("Please fill out a new customer or player", 2);
        } else {
            if (customerResError) {
                Toast.fail(customerResError, 1.5);
            } else {
                Toast.success(this.successMessage, 1.5);
                this.props.history.push("/charges/new");
            }
        }
    }

    @action.bound
    handleSubmit = () => {
        this.loading = true;
        // Prevent player creation withour customer id
        if (
            !this.customerEntered &&
            this.playerEntered &&
            !this.props.store.Customer.activeCustomer.id
        ) {
            this.loading = false;
            Toast.info(
                "Please select or create a customer before creating a player",
                3
            );
            this.props.history.push(
                `/jobs/${this.props.store.Job.activeJob.id}`
            );
        } else if (
            (this.customerEntered && this.customer.email.length < 1) ||
            !Validate.email(this.customer.email)
        ) {
            Toast.fail("Please enter a valid email for your customer", 2);
            this.loading = false;
        } else {
            this.createCustomerAndOrPlayer();
        }
    };

    componentDidMount() {
        if (
            !this.props.store.Job.activeJob.id &&
            !this.props.store.Customer.activeCustomer.id
        ) {
            Toast.info("Please select a job before creating a customer", 2.5);
            this.props.history.push("/");
        }

        if (this.props.store.Customer.activeCustomer.id) {
            this.customerDisabled = true;
            this.customer = this.props.store.Customer.activeCustomer;
        }
    }

    render() {
        return (
            <div className="new-customer-page">
                <div className="text-center">
                    <h2>Customer Information</h2>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <InputItem
                        clear
                        placeholder="Name"
                        value={this.customer.name}
                        disabled={this.customerDisabled}
                        onChange={(event) => this.handleChange("name", event)}
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Email"
                        value={this.customer.email}
                        disabled={this.customerDisabled}
                        onChange={(event) => this.handleChange("email", event)}
                    >
                        <img src="/assets/envelope.svg" alt="envelope" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Phone"
                        value={this.customer.phone}
                        disabled={this.customerDisabled}
                        onChange={(event) => this.handleChange("phone", event)}
                    >
                        <img src="/assets/phone.svg" alt="phone" />
                    </InputItem>

                    <div className="text-center">
                        <h2>Player Information</h2>
                        <p style={{ color: "#888" }}>ONE ENTRY PER PLAYER</p>
                    </div>

                    <InputItem
                        clear
                        placeholder="First Name"
                        value={this.player.first_name}
                        onChange={(event) =>
                            this.handlePlayerChange("first_name", event)
                        }
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Last Name"
                        value={this.player.last_name}
                        onChange={(event) =>
                            this.handlePlayerChange("last_name", event)
                        }
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Team"
                        value={this.player.team}
                        onChange={(event) =>
                            this.handlePlayerChange("team", event)
                        }
                    >
                        <img src="/assets/jersey.svg" alt="jersey" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Jersey #"
                        value={this.player.number}
                        onChange={(event) =>
                            this.handlePlayerChange("number", event)
                        }
                    >
                        <img src="/assets/number.svg" alt="number" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Age / Div."
                        value={this.player.notes}
                        onChange={(event) =>
                            this.handlePlayerChange("notes", event)
                        }
                    >
                        <img src="/assets/nametag.svg" alt="nametag" />
                    </InputItem>
                    <br />
                    <InputItem
                        clear
                        placeholder="Notes / Position"
                        value={this.player.position}
                        onChange={(event) =>
                            this.handlePlayerChange("position", event)
                        }
                    >
                        <img src="/assets/info.svg" alt="position" />
                    </InputItem>
                    <div className="text-center">
                        <h2>Agreement</h2>
                    </div>
                    <AgreeItem
                        data-seed="logId"
                        onChange={(e) =>
                            this.setState({ agreed: e.target.checked })
                        }
                    >
                        I agree to use these photos for personal use only unless
                        expressly stated otherwise by the photographer.
                    </AgreeItem>
                </form>

                <div className="flex-center">
                    <Button
                        type="primary"
                        onClick={this.handleSubmit}
                        className="gf-button"
                        disabled={!this.state.agreed}
                    >
                        {this.loading ? (
                            <Icon type="loading" />
                        ) : (
                            "TAKE PAYMENT"
                        )}
                    </Button>
                </div>
                <br />
            </div>
        );
    }
}

export default CustomerNew;
