// Packages
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { observable } from "mobx";
// Styles
import { TabBar } from "antd-mobile";
import UpdatesPopup from "../../compoents/popups/Updates";
import Zoho from "../../modules/Zoho";

class AppLayout extends React.Component {
    @observable jobsSelected = this.props.location.pathname === "/";
    @observable paymentsSelected =
        this.props.location.pathname === "/stripe-settings";
    @observable accountSelected =
        this.props.location.pathname === "/accounts/edit";
    @observable salesSelected = this.props.location.pathname === "/sales";

    getToken = () => {
        return localStorage.getItem("capturelife-token");
    };

    componentDidMount() {
        Zoho.displayZoho();
    }

    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(routeProps) =>
                    !this.getToken() ? (
                        <Redirect to="/sign-in" />
                    ) : (
                        <div className="app-layout">
                            <UpdatesPopup history={this.props.history} />
                            <TabBar
                                unselectedTintColor="#949494"
                                tintColor="#005287"
                                barTintColor="white"
                            >
                                <TabBar.Item
                                    title="Jobs"
                                    key="Jobs"
                                    icon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/jobs.svg)",
                                            }}
                                        />
                                    }
                                    selectedIcon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/jobs-active.svg)",
                                            }}
                                        />
                                    }
                                    selected={this.jobsSelected}
                                    onPress={() => {
                                        this.jobsSelected = true;
                                        this.paymentsSelected = false;
                                        this.accountSelected = false;
                                        this.salesSelected = false;
                                        this.props.history.push("/");
                                    }}
                                >
                                    <Component {...routeProps} />
                                </TabBar.Item>
                                <TabBar.Item
                                    title="Payments"
                                    key="Payments"
                                    icon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/info.svg)",
                                            }}
                                        />
                                    }
                                    selectedIcon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/info-active.svg)",
                                            }}
                                        />
                                    }
                                    selected={this.paymentsSelected}
                                    onPress={() => {
                                        this.jobsSelected = false;
                                        this.paymentsSelected = true;
                                        this.accountSelected = false;
                                        this.salesSelected = false;
                                        this.props.history.push(
                                            "/stripe-settings"
                                        );
                                    }}
                                >
                                    <Component {...routeProps} />
                                </TabBar.Item>
                                <TabBar.Item
                                    title="Account"
                                    key="Account"
                                    icon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/user.svg)",
                                            }}
                                        />
                                    }
                                    selectedIcon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/user-active.svg)",
                                            }}
                                        />
                                    }
                                    selected={this.accountSelected}
                                    onPress={() => {
                                        this.jobsSelected = false;
                                        this.paymentsSelected = false;
                                        this.accountSelected = true;
                                        this.salesSelected = false;
                                        this.props.history.push(
                                            "/accounts/edit"
                                        );
                                    }}
                                >
                                    <Component {...routeProps} />
                                </TabBar.Item>
                                <TabBar.Item
                                    title="Sales"
                                    key="Sales"
                                    icon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/dollar.svg)",
                                            }}
                                        />
                                    }
                                    selectedIcon={
                                        <div
                                            className="tab-bar-icon"
                                            style={{
                                                backgroundImage:
                                                    "url(/assets/dollar-active.svg)",
                                            }}
                                        />
                                    }
                                    selected={this.salesSelected}
                                    onPress={() => {
                                        this.jobsSelected = false;
                                        this.paymentsSelected = false;
                                        this.accountSelected = false;
                                        this.salesSelected = true;
                                        this.props.history.push("/sales");
                                    }}
                                >
                                    <Component {...routeProps} />
                                </TabBar.Item>
                            </TabBar>
                        </div>
                    )
                }
            />
        );
    }
}

export default withRouter(AppLayout);
