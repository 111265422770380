import React from "react";
import { Modal } from "antd-mobile";
import Settings from "../../modules/settings";
import { inject } from "mobx-react";
import GfcrewUpdates from "../../modules/GfcrewUpdates";

const alert = Modal.alert;

@inject("store")
class Updates extends React.Component {
    state = {
        updates: {
            date: null,
            version: null,
            updates: null,
        },
    };

    async loadUpdatesFromServerIntoState() {
        this.setState({
            updates: await GfcrewUpdates.getUpdatesFromServer(),
        });
    }

    getCurrentVersion() {
        return this.state.updates.version;
    }

    displayUpdates() {
        alert(
            "App Updates",
            "A new version has been released. Click 'View Updates' to read about the changes.",
            [
                { text: "Close", onPress: () => console.log("cancel") },
                {
                    text: "View Updates",
                    onPress: () => this.props.history.push("/updates"),
                },
            ]
        );
    }

    updateUserVersion(accountSettings) {
        accountSettings.version = this.getCurrentVersion();
        accountSettings.account_id = this.props.store.Account.account.id;
        this.props.store.Account.setAccountSettings(
            new Settings(accountSettings)
        );
    }
    async showUserUpdates() {
        let accountId = this.props.store.Account.account.id;
        if (accountId > 0) {
            let accountSettings = await this.props.store.Account.getAccountSettings(
                accountId
            );

            if (accountSettings.version < this.getCurrentVersion()) {
                this.displayUpdates();
                this.updateUserVersion(accountSettings);
            }
        }
    }
    async componentDidMount() {
        await this.loadUpdatesFromServerIntoState();
        setTimeout(() => {
            this.showUserUpdates();
        }, 1500);
    }
    render() {
        if (this.state.updates.version === null) {
            return "";
        }
        return <div></div>;
    }
}
export default Updates;
