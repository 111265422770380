// Packages
import React from "react";
import { action, observable, computed, reaction } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import { withRouter } from "react-router-dom";

// Components
import { InputItem, Button, Icon, Toast, Modal, List } from "antd-mobile";
import PreSignUp from "../../modules/presignup";
import { CardElement, injectStripe } from "react-stripe-elements";

import CaptureLifeApi from '../../modules/CaptureLifeApi'

import { AntOutline, CheckOutline } from 'antd-mobile-icons'
import { blue } from "@material-ui/core/colors";

const cardFormStyles = () => {
    return {
        style: {
            base: {
                fontSize: "17px",
                fontFamily: "Roboto, sans-serif",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#c23d4b",
            },
        },
    };
};

@inject("store")
@observer
class ChargeForm extends React.Component {
    state = {
        job: null,
        customer: null,
        amount: 0,
        isModal: false,
        photographer: null,
    };
    @observable
    loading = false;
    handleSubmit = async () => {

        console.log('updated 9:11 PM');

        this.loading = true;

        let job = await this.getJob();
        console.log(job);

        let paidCustomers = job.charged / job.default_fee;
        if (job.max_registration <= paidCustomers) {
            Modal.alert(
                "Spot Unavailable",
                "Oops! Sorry, that spot is no longer available. Please contact me to double check.",
                [
                    {
                        text: "Go Back",
                        onPress: () => {
                            this.props.history.push(
                                "/register/" + job.account_id
                            );
                        },
                    },
                ]
            );
            return;
        }

        let api = new CaptureLifeApi(
            process.env.REACT_APP_PRE_SIGNUP_CLIENT_TOKEN
        );

        var settings = await api.getAccountSetting(this.state.customer.account_id);

        this.setState({
            photographer : settings
        });

        let amount = this.state.amount;
        let customerId = this.state.customer.id;
        let signup = new PreSignUp();
        const token = await this.props.stripe.createToken({
            type: "card",
            name: this.state.customer.name,
        });
        
        if (token.error) {
            this.loading = false;
            Toast.fail(token.error.message, 1.5);
            return;
        }

        let res = await signup.createCharge(amount, token.token.id, customerId);
        // // let res = {};
        // // res.success = true;

        if (res.success) {
            signup.createPlayer(customerId, PreSignUp.getPlayer());

            this.setState({ isModal: true });
            signup.recordRegisterWebHook(res.data.id);

        } else {
            Toast.success(res.message, 2);
        }
        this.loading = false;
    };

    successRedirect = () => {
        this.props.history.push(
            "/register/" + this.state.customer.account_id
        );
    }

    isOverMinChargeAmount() {
        return parseInt(this.amount) < process.env.REACT_APP_MINIMUM_FEE;
    }

    getJobid() {
        return this.props.match.params.id;
    }

    async getJob() {

        let signup = new PreSignUp();
        let jobs = await signup.getJobs(localStorage.getItem('accountId'));

        if (jobs) {
            let selectedJob = jobs.filter((job) => job.id == this.getJobid());
            PreSignUp.setSelectedJob(JSON.stringify(selectedJob));
            this.setState({ job: selectedJob });
            return selectedJob[0];
        } else {
            return false;
        }
    }

    componentDidMount() {
        let job = false;
        let customer = false;

        try {
            customer = PreSignUp.getCustomer();
            job = PreSignUp.getSelectedJob()[0];
        } catch (err) { }


        if (job && customer) {
            this.setState({
                job: job,
                customer: customer,
                amount: job.default_fee / 100,
            });
        } else {
            this.props.history.push("/");
        }
    }

    render() {
        return (
            <div className="charge-form">
                <Modal
                    closable={false}
                    visible={this.state.isModal}
                    className={'chargeSuccessModal'}>

                    <div className="modal-header">
                        <h1>{this.state.photographer ? this.state.photographer.name : ''}</h1>
                    </div>
                    <hr clasName = "chargeHr"/>
                    <div className="modal-body">
                        <CheckOutline fontSize={96} color='var(--adm-color-primary)'/>
                        <h2>THANKS FOR REGISTERING!</h2>
                        <text>We appreciate you registering for photos with us. If you have any questions please contact us at </text> 
                        <a href={this.state.photographer? "mailto:" + this.state.photographer.email : ''}>
                            {this.state.photographer? this.state.photographer.email : ''}
                        </a>
                        <text> and we will get back to you as soon as possible.</text>
                    </div>
                    <Button
                        type = {'primary'}
                        className={'chargeThankBtn gf-button'}
                        onClick={this.successRedirect}>
                        Main Menu
                    </Button>
                </Modal>

                <InputItem
                    clear
                    placeholder="Amount"
                    extra="dollar(s)"
                    type="number"
                    value={this.state.amount}
                >
                    <img src="/assets/dollar.svg" alt="dollar" />
                </InputItem>

                <br />

                <form className="full-width-form">
                    <div className="stripe-input-container">
                        <CardElement {...cardFormStyles()} />
                    </div>
                    <br />
                </form>

                <div className="buttons">
                    <Button
                        type="warning"
                        onClick={this.handleSubmit}
                        disabled={this.loading}
                    >
                        {this.loading ? <Icon type="loading" /> : "CHARGE"}
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(injectStripe(ChargeForm));
