const getPaidCustomers = (job) => {
    let paidCustomers = Number(job.charged) / Number(job.default_fee);
    return paidCustomers | 0;
};
const getSpotsAvailable = (job) => {
    let spots = job.max_registration;

    if (job.charged > 0) {
        spots = job.max_registration - getPaidCustomers(job);
    }

    if (spots < 0) {
        spots = 0;
    }

    return spots;
};

/**
 *
 * @param {*} date
 * @returns Date Object
 */
const formatDate = (date) => {
    try {
        if (!date) {
            return new Date();
        }
        if (typeof date === "string" || date instanceof String) {
            return new Date(date.substring(0, 10) + "T12:00:00");
        } else {
            return date;
        }
    } catch (err) {
        return new Date();
    }
};

exports.getSpotsAvailable = getSpotsAvailable;
exports.formatDate = formatDate;
