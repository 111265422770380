// Packages
import React from "react";
import { inject, observer } from "mobx-react";
import PreSignUp from "../../modules/presignup";
import CaptureLifeApi from "../../modules/CaptureLifeApi";
import JobUtil from "../../modules/jobUtilities";
// Components
import { Card, Icon, SearchBar, WingBlank, Toast } from "antd-mobile";
import { Link } from "react-router-dom";
import async from "async";

import axios from "axios";

@inject("store")
@observer
class ListEvents extends React.Component {
    state = {
        jobs: null,
        settings: null,
        loading: true,
    };

    constructor(props) {
        super(props);
    }

    getAccountId() {
        return this.props.match.params.id;
    }

    getFee(job) {
        return "$" + job.default_fee / 100;
    }


    getSpotsAvailable(job) {
        let spots = JobUtil.getSpotsAvailable(job);
        return spots === 0 ? "SOLD OUT" : spots + " Spots available";
    }

    async componentDidMount() {

        Toast.loading("Fetching events...", 20);
        let api = new CaptureLifeApi(
            process.env.REACT_APP_PRE_SIGNUP_CLIENT_TOKEN
        );
        let signup = new PreSignUp();
        let accountId = this.getAccountId();

        // let accountData = await this.props.store.Account.getAccount(
        //         this.props.history
        //     );

        // console.log(accountData);   

        // var token = localStorage.getItem("capturelife-token");
        signup.clearLocalStorage();
        // localStorage.setItem("capturelife-token", token);
        await new Promise((r) => setTimeout(r, 3000));
        

        this.setState({
            jobs: await signup.getJobs(accountId),
            settings: await api.getAccountSetting(accountId),
            loading: false,
        });

        console.log(this.state.settings);
        Toast.hide();
    }

    getCompanyName() {
        if (this.state.settings && this.state.settings.name) {
            return (
                <>
                    WELCOME TO
                    <br />
                    {this.state.settings.name.toUpperCase()}
                    <br /><br />
                    <div className = "red">{this.state.settings.note}</div>
                    <br />
                    SELECT YOUR EVENT TO REGISTER
                </>
            );
        }
    }
    getContactInfomation() {
        if (!this.state.settings) return;

        let settings = this.state.settings;

        if (!settings) return;

        if (settings.isShowPhone() && settings.isShowEmail()) {
            return (
                <>
                    <a href={"tel:" + this.state.settings.phone}>
                        {this.state.settings.phone}
                    </a>{" "}
                    |{" "}
                    <a href={"mailto:" + this.state.settings.email}>
                        {this.state.settings.email}
                    </a>
                    {this.state.settings.sample ?
                        <> 
                            {" | "}
                            <a href={"http://" + this.state.settings.sample} target = "_blank">
                                {"View Samples"}
                            </a>
                        </>
                        : ''
                    }
                </>
            );
        }
        if (settings.isShowEmail()) {
            return (
                <>
                    <a href={"mailto:" + this.state.settings.email}>
                        {this.state.settings.email}
                    </a>
                    {this.state.settings.sample ?
                        <> 
                            {" | "}
                            <a href={"http://" + this.state.settings.sample} target = "_blank">
                                {"View Samples"}
                            </a>
                        </>
                        : ''
                    }
                </>
            );
        }
        if (settings.isShowPhone()) {
            return (
                <>
                    <a href={"tel:" + this.state.settings.phone}>
                        {this.state.settings.phone}
                    </a>
                    {this.state.settings.sample ?
                        <> 
                            {" | "}
                            <a href={"http://" + this.state.settings.sample} target = "_blank">
                                {"View Samples"}
                            </a>
                        </>
                        : ''
                    }
                </>
            );
        }
    }

    render() {
        return (
            <>
                <div className="container">
                    {this.showSearchBox && (
                        <SearchBar
                            placeholder="Search jobs..."
                            value={this.searchTerm}
                            onChange={this.search}
                            onCancel={() => (this.searchTerm = "")}
                        />
                    )}

                    <WingBlank>
                        <h3 style={{ textAlign: "center" }}>
                            {this.getCompanyName()}
                        </h3>
                        <p style={{ textAlign: "center" }}>
                            {this.getContactInfomation()}
                        </p>
                    </WingBlank>

                    {!this.state.jobs || this.state.jobs.length === 0 ? (
                        <>
                            {this.state.loading === false && (
                                <Card className="job-card">
                                    <Card.Body>
                                        No events are currently scheduled.
                                        Please contact me if you would like to
                                        schedule an event for your player!
                                    </Card.Body>
                                </Card>
                            )}
                        </>
                    ) : (
                        this.state.jobs.map((job) => {
                            return (
                                <Link
                                    to={this.getSpotsAvailable(job) === 'SOLD OUT'?'#':`/register/customer/${job.id}`}
                                    data = {{accountId : this.props.match.params.id}}
                                    key={job.id}
                                    className = {this.getSpotsAvailable(job) === 'SOLD OUT'? 'disabled' : ''}
                                >
                                    <Card className="job-card">
                                        <Card.Header
                                            title={
                                                job.name ? (
                                                    <span>{job.name} </span>
                                                ) : (
                                                    "No job name found"
                                                )
                                            }
                                            extra={
                                                <>
                                                    <img
                                                        src="/assets/cal-primary.svg"
                                                        className="inline-icon"
                                                        alt="calendar"
                                                    />
                                                    <span>
                                                        {job.start_date
                                                            ? job.start_date.substring(
                                                                  0,
                                                                  10
                                                              )
                                                            : "No start date found"}
                                                    </span>
                                                </>
                                            }
                                        />
                                        <Card.Body>
                                            <>
                                                <img
                                                    src="/assets/nametag.svg"
                                                    className="inline-icon"
                                                    alt="calendar"
                                                />
                                                <span>Pre Register</span>
                                                {this.getSpotsAvailable(job) !== 'SOLD OUT' ? <Icon
                                                    type="right"
                                                    className="clickable job-detail-button primary-text"
                                                /> : ''}
                                            </>
                                        </Card.Body>
                                        <Card.Footer
                                            content={this.getSpotsAvailable(
                                                job
                                            )}
                                            extra={this.getFee(job)}
                                        />
                                    </Card>
                                </Link>
                            );
                        })
                    )}
                </div>
            </>
        );
    }
}

export default ListEvents;
