// Packages
import React from "react";
import { computed, observable, action } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { Link } from "react-router-dom";
import {
    Card,
    Icon,
    NavBar,
    Toast,
    SegmentedControl,
    Button,
} from "antd-mobile";

@inject("store")
@observer
class CustomerDetail extends React.Component {
    @observable loading = false;
    @observable segment = "Players";
    @observable disableRefunds = false;

    @computed get segmentIndex() {
        if (this.segment === "Players") {
            return 0;
        } else {
            return 1;
        }
    }
    @computed get customer() {
        if (this.props.store.Customer.activeCustomer) {
            return this.props.store.Customer.activeCustomer;
        } else {
            return {};
        }
    }

    @computed get customerId() {
        return Number(this.props.match.params.id);
    }

    @computed get players() {
        return this.props.store.Customer.players;
    }

    @computed get charges() {
        if (this.props.store.Customer.customerCharges === null) {
            return [];
        }
        return this.props.store.Customer.customerCharges;
    }

    @action.bound
    segmentSelection = (value) => {
        this.segment = value.split(" ")[0];
    };

    @action.bound
    getPlayers() {
        this.props.store.Customer.getPlayers().then((players) => {
            this.loading = false;
        });
    }

    @action.bound
    deleteCustomer() {
        this.props.store.Customer.deleteCustomer(this.customerId);
    }

    @action.bound
    refundCharge(chargeId, amount) {
        this.props.store.Charge.refundCharge(chargeId, amount);
        Toast.success("Successfully refunded charge.");
        this.props.history.goBack();
    }

    componentDidMount() {
        if (!this.customer.id || this.customer.id !== this.customerId) {
            this.loading = true;
        }

        this.props.store.Customer.getCustomer(this.customerId)
            .then((customer) => {
                if (customer.id) {
                    this.getPlayers();
                }
            })
            .catch((err) => {
                Toast.fail("Customer lookup failed. Please try again.");
                this.props.history.push("/");
            });
    }

    render() {
        return (
            <>
                <NavBar
                    mode="light"
                    icon={<Icon type="left" className="clickable" />}
                    onLeftClick={() => this.props.history.goBack()}
                >
                    <img src="/assets/logo.png" alt="GF Crew Logo" />
                </NavBar>

                <div className="container">
                    {this.customer.id && this.customer.id === this.customerId && (
                        <Card className="job-card" key={this.customer.id}>
                            <Card.Header
                                title={
                                    this.customer.name
                                        ? this.customer.name
                                        : "No customer name found"
                                }
                                extra={
                                    <span className="primary-badge badge">
                                        ${this.customer.charged / 100}
                                    </span>
                                }
                            />
                            <Card.Body>
                                <img
                                    src="/assets/phone-warning.svg"
                                    className="inline-icon"
                                    alt="phone"
                                />
                                <span className="primary-text">
                                    {this.customer.phone
                                        ? this.customer.phone
                                        : "No phone found"}
                                </span>
                            </Card.Body>
                            <Card.Footer
                                content={
                                    <>
                                        <img
                                            src="/assets/envelope-warning.svg"
                                            className="inline-icon"
                                            alt="envelope"
                                        />
                                        <span className="primary-text">
                                            {" "}
                                            {this.customer.email
                                                ? this.customer.email
                                                : "No email found"}
                                        </span>
                                    </>
                                }
                                extra={
                                    <Link
                                        to={`/customers/${this.customer.id}/edit`}
                                    >
                                        <img
                                            src="/assets/edit-button.svg"
                                            className="inline-icon"
                                            alt="edit button"
                                        />
                                        <span className="primary-text">
                                            Edit
                                        </span>
                                    </Link>
                                }
                            />
                        </Card>
                    )}
                    <div className="flex-center">
                        <Link
                            to="/charges/new"
                            className="block-button am-button am-button-primary clickable"
                        >
                            TAKE PAYMENT
                        </Link>
                    </div>
                    <div className="flex-center">
                        <Link
                            to="/customers/new"
                            className="block-button am-button am-button-warning clickable"
                        >
                            ADD PLAYER
                        </Link>
                    </div>

                    {!this.loading && (
                        <>
                            <SegmentedControl
                                className="customer-detail-segment-control"
                                selectedIndex={this.segmentIndex}
                                values={[
                                    `Players (${this.players.length})`,
                                    `Charges (${this.charges.length})`,
                                ]}
                                onValueChange={this.segmentSelection}
                            />
                        </>
                    )}

                    <br />

                    {this.players.length > 0 &&
                        this.segment === "Players" &&
                        !this.loading &&
                        this.players.map((player) => {
                            return (
                                <Card className="job-card" key={player.id}>
                                    <Card.Header
                                        title={`${player.first_name} ${player.last_name}`}
                                        extra={
                                            <Link
                                                to={`/players/${player.id}/edit`}
                                                onClick={() =>
                                                    (this.props.store.Customer.activePlayer = player)
                                                }
                                            >
                                                <img
                                                    src="/assets/edit-button.svg"
                                                    className="inline-icon"
                                                    alt="edit button"
                                                />
                                                <span className="primary-text">
                                                    Edit
                                                </span>
                                            </Link>
                                        }
                                    />
                                    <Card.Body>
                                        <span className="primary-text">
                                            {player.team
                                                ? player.team
                                                : "No team found"}
                                        </span>
                                    </Card.Body>
                                    <Card.Footer
                                        content={
                                            <>
                                                <img
                                                    src="/assets/number-warning.svg"
                                                    className="inline-icon"
                                                    alt="number"
                                                />
                                                <span className="primary-text">
                                                    {" "}
                                                    {player.number
                                                        ? player.number
                                                        : "No number found"}
                                                </span>
                                            </>
                                        }
                                        extra={
                                            <>
                                                <img
                                                    src="/assets/nametag-warning.svg"
                                                    className="inline-icon"
                                                    alt="nametag"
                                                />
                                                <span className="primary-text">
                                                    {player.notes
                                                        ? player.notes
                                                        : "No division found"}
                                                </span>
                                            </>
                                        }
                                    />
                                </Card>
                            );
                        })}

                    {this.charges !== null &&
                        this.segment === "Charges" &&
                        !this.loading &&
                        this.charges.map((charge) => {
                            return (
                                <Card className="job-card" key={charge.id}>
                                    <Card.Header
                                        title={<div>Charge</div>}
                                        extra={
                                            <span className="primary-badge badge">
                                                ${charge.amount / 100}
                                            </span>
                                        }
                                    />
                                    <Card.Body>
                                        <>
                                            <img
                                                src="/assets/cal-primary.svg"
                                                className="inline-icon"
                                                alt="calendar"
                                            />
                                            <span>
                                                {charge.created_at
                                                    ? charge.created_at.substring(
                                                          0,
                                                          10
                                                      )
                                                    : "No date found"}
                                            </span>
                                            {charge.refunds === undefined && (
                                                <Button
                                                    className="clickable"
                                                    type="ghost warning"
                                                    inline
                                                    size="small"
                                                    style={{ float: "right" }}
                                                >
                                                    Refund Charge
                                                </Button>
                                            )}
                                        </>
                                    </Card.Body>
                                    <Card.Footer
                                        content={
                                            <>
                                                {charge.refunds !== null && (
                                                    <div>
                                                        <img
                                                            src="/assets/dollar.svg"
                                                            className="inline-icon"
                                                            alt="Dollar Sign"
                                                        />
                                                        <span>
                                                            Charge Refunded
                                                        </span>
                                                    </div>
                                                )}
                                                {charge.refunds === null && (
                                                    <button
                                                        className="primary-badge badge"
                                                        onClick={() => {
                                                            this.refundCharge(
                                                                charge.id,
                                                                charge.amount
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/dollar.svg"
                                                            className="inline-icon"
                                                            alt="Dollar Sign"
                                                        />
                                                        <span>
                                                            Refund Charge
                                                        </span>
                                                    </button>
                                                )}
                                            </>
                                        }
                                        extra={
                                            <>
                                                {charge.refunds !== null && (
                                                    <div>
                                                        Date{" "}
                                                        {charge.refunds[
                                                            charge.refunds
                                                                .length - 1
                                                        ].created_at.substring(
                                                            0,
                                                            10
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        }
                                    />
                                </Card>
                            );
                        })}

                    {this.loading && <Icon type="loading" />}
                </div>
            </>
        );
    }
}

export default CustomerDetail;
