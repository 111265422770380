import React from "react";
import { inject } from "mobx-react";
import { List, Badge, Picker, Flex } from "antd-mobile";

const Item = List.Item;

const SORT_ORDER_DESC = 0;
const SORT_ORDER_ASC = 1;
const ALL_MONTHS_FILTER_VALUE = 13;
const MONTH_FILTERS = [
    { label: "All Months", value: ALL_MONTHS_FILTER_VALUE },
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 },
];
const YEAR_FILTERS = [
    { label: "2018", value: 2018 },
    { label: "2019", value: 2019 },
    { label: "2020", value: 2020 },
    { label: "2021", value: 2021 },
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
];
@inject("store")
class SalesSummary extends React.Component {
    state = {
        jobs: [],
        allJobs: [],
        loading: true,
        sortOrder: SORT_ORDER_DESC,
        activeYear: 2020,
        activeMonth: 13,
    };

    filterByMonth(month) {
        let filteredJobs =
            month[0] === ALL_MONTHS_FILTER_VALUE
                ? this.state.allJobs
                : this.state.allJobs.filter((job) => {
                      let endDate = new Date(job.end_date);
                      return endDate.getMonth() === month[0];
                  });
        this.setState({ jobs: filteredJobs, activeMonth: month[0] });
    }

    filterByYear(year) {
        let selectedYear = year[0];
        let filteredJobs = this.state.allJobs.filter((job) => {
            let endDate = new Date(job.end_date);
            return endDate.getFullYear() === selectedYear;
        });

        this.setState({ jobs: filteredJobs, activeYear: selectedYear });
    }

    getFormatedDate(date) {
        let dateObj = new Date(date);
        return dateObj.toLocaleDateString("en-US");
    }

    sortJobsAsc() {
        this.state.jobs.sort(
            (a, b) => new Date(a.end_date) - new Date(b.end_date)
        );
    }

    sortJobsDesc() {
        this.state.jobs.sort(
            (a, b) => new Date(b.end_date) - new Date(a.end_date)
        );
    }

    sortJobsByDate() {
        let sortOrder = this.state.sortOrder;

        if (sortOrder === SORT_ORDER_DESC) {
            this.sortJobsAsc();
            this.setState({
                sortOrder: SORT_ORDER_ASC,
            });
        } else {
            this.sortJobsDesc();
            this.setState({
                sortOrder: SORT_ORDER_DESC,
            });
        }
    }

    getSalesTotal() {
        return this.getTotalBadge(this.state.allJobs);
    }

    getFilteredSalesTotal() {
        return this.getTotalBadge(this.state.jobs);
    }

    getTotalBadge(jobs) {
        let total = jobs.reduce(
            (accumulator, job) => accumulator + job.charged,
            0
        );
        return (
            <Badge
                text={"$" + total / 100}
                style={{
                    backgroundColor: "#e11f26",
                }}
            />
        );
    }

    async componentDidMount() {
        this.setState({
            jobs: this.props.allJobs,
            allJobs: this.props.allJobs,
        });
        this.sortJobsDesc();
    }

    render() {
        return (
            <div>
                <div>
                    <List className="my-list">
                        <Item
                            key="jobs-total"
                            extra={this.state.allJobs.length}
                        >
                            Total Jobs:
                        </Item>
                        <Item key="sales-total" extra={this.getSalesTotal()}>
                            Total Sales:
                        </Item>
                        <Item
                            key="filter-total"
                            extra={this.getFilteredSalesTotal()}
                        >
                            Filtered Sales:
                        </Item>
                    </List>
                    <br />
                    <Flex>
                        <Flex.Item>
                            <Picker
                                data={YEAR_FILTERS}
                                cols={1}
                                value={[this.state.activeYear]}
                                onOk={(val) => this.filterByYear(val)}
                            >
                                <List.Item key="filter" arrow="horizontal">
                                    Year
                                </List.Item>
                            </Picker>
                        </Flex.Item>
                    </Flex>
                    <Flex>
                        <Flex.Item>
                            <Picker
                                data={MONTH_FILTERS}
                                value={[this.state.activeMonth]}
                                cols={1}
                                onOk={(val) => this.filterByMonth(val)}
                            >
                                <List.Item key="filter" arrow="horizontal">
                                    Month
                                </List.Item>
                            </Picker>
                        </Flex.Item>
                        {/* <Flex.Item>
                            <div>
                                <a
                                    className="am-list-item am-list-item-middle"
                                    onClick={this.sortJobsByDate.bind(this)}
                                >
                                    <img
                                        src={
                                            this.state.sortOrder ===
                                            SORT_ORDER_DESC
                                                ? "/assets/clock1.svg"
                                                : "/assets/clock2.svg"
                                        }
                                        className="inline-icon"
                                        alt="phone"
                                    />
                                    Sort by date
                                </a>
                            </div>
                        </Flex.Item> */}
                    </Flex>
                    <br />
                </div>

                <List>
                    {this.state.jobs.length > 0 &&
                        this.state.jobs.map((job, index) => {
                            return (
                                <div key={job.name + "-" + index}>
                                    <div
                                        key={job.name}
                                        style={{
                                            display: "block",
                                            textAlign: "left",
                                            paddingLeft: "15px",
                                            paddingTop: "15px",
                                            fontSize: "17px",
                                        }}
                                    >
                                        {job.name}
                                    </div>

                                    <Item
                                        key={index}
                                        extra={job.subject_count + " Sessions"}
                                        align="top"
                                        multipleLine
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    color: "#888",
                                                }}
                                            >
                                                {this.getFormatedDate(
                                                    job.end_date
                                                )}
                                            </span>

                                            <span
                                                style={{
                                                    color: "#888",
                                                    float: "right",
                                                }}
                                            >
                                                ${job.charged / 100}
                                            </span>
                                        </div>
                                    </Item>
                                </div>
                            );
                        })}
                </List>
            </div>
        );
    }
}
export default SalesSummary;
