import React from "react";
import { Button, Toast, Picker } from "antd-mobile";
import { inject } from "mobx-react";

@inject("store")
class MoveCustomerBtn extends React.Component {
    state = {
        moveCustomerModal: false,
        disableMove: false,
        cols: 1,
        jobs: [],
        value: [],
    };

    async moveCustomerToJob(jobId) {
        if(this.state.disableMove) {
            Toast.fail("Cannot move customer.");
            return;
        }
            

        try {
            await this.props.store.Customer.moveToNewJob(jobId);
            Toast.success("Customer has been moved", 2);
            this.props.history.push("/");
        } catch (err) {
            console.log(err);
            Toast.fail(
                "An error has occuried. We are unable to move this customer.",
                2
            );
        }
    }

    isStartDatePassed(job) {
        return new Date() > new Date(job.start_date);
    }

    async getJobs() {
        let jobStateData = [];
        let jobs = await this.props.store.Job.getFutureJobsSortedByEndDate();

        jobs.forEach((job) => {
            jobStateData.push({
                label: `${job.name} (${job.start_date.substring(0, 10)})`,
                value: job.id,
            });
        });

        return jobStateData;
    }

    async componentDidMount() {
        let jobs = await this.getJobs();
        if (jobs.length > 0) {
            this.setState({
                jobs: [jobs],
                disableMove: false,
            });
        } else {
            this.setState({
                jobs: [[{label: "<No Events to Choose>", value: 'lol'}]],
                disableMove: true,
            });
        }
    }

    render() {
        return (
            <Button
                type="ghost"
                className="block-button am-button am-button-primary clickable"
            >
                <Picker
                    data={this.state.jobs}
                    cols={this.state.cols}
                    cascade={false}
                    okText="Move"
                    value={this.state.value}
                    onOk={(val) => this.moveCustomerToJob(val)}
                >
                    <Button>MOVE TO ANOTHER JOB</Button>
                </Picker>
            </Button>
        );
    }
}
export default MoveCustomerBtn;
