export default class Validator {
    static email(email) {
        let trimmedEmail = email.trim();
        var re = /\S+@\S+\.\S+/;
        return re.test(trimmedEmail);
    }
    static phone(phone) {
        if (phone === undefined || phone === null) {
            return false;
        }

        if (Number.isInteger(phone)) {
            phone = phone.toString();
        }

        let trimmedPhone = phone.trim();
        var re = /((?:\s|^)(?:\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})(?:\b)/;
        return re.test(trimmedPhone);
    }
}
