const START_OF_FIRST_PAY_PERIOD = 22;
const END_OF_FIRST_PAY_PERIOD = 6;
const START_OF_SECOND_PAY_PERIOD = 7;
const END_OF_SECOND_PAY_PERIOD = 21;

const STRIPE_STATUS_CREATED = 0;
const STRIPE_STATUS_PENDING = 1;
const STRIPE_STATUS_COMPLETE = 2;

export default class PayPeriod {
    /**
     *
     * @param {array} transfers
     *  Array of transfer objects.
     *  [{
     *       id: "",
     *       status: "Pending",
     *       amount: 0,
     *       updated: "2020-01-07T10:34:02Z",
     *       created: "2020-01-07T10:34:02Z",
     *  },...]
     */
    constructor(transfers) {
        this.stripeTransfers = this.getUniqueTransfers(transfers);
        this.year = new Date().getFullYear();
    }

    filterTransfersByYear(year) {
        this.stripeTransfers = this.stripeTransfers.filter((transfer) => {
            return new Date(transfer.created).getFullYear() === this.year;
        });
    }

    getUniqueTransfers(transfers) {
        let transferIds = [];
        let uniqueTransfers = [];

        for (const transfer of transfers) {
            if (!transferIds.includes(transfer.id)) {
                uniqueTransfers.push(transfer);
                transferIds.push(transfer.id);
            }
        }

        return uniqueTransfers;
    }

    getPayPeriodsForTheYear(year) {
        this.year = year;
        let payPeriods = [];
        this.filterTransfersByYear(year);

        for (let month = 1; month < 13; month++) {
            payPeriods.push(this.getFirstPayPeriodOfTheMonth(month));
            payPeriods.push(this.getSecondPayPeriodOfTheMonth(month));
        }

        return payPeriods;
    }

    /**
     *
     * @param {int} month Numerical month 1-12
     */
    getFirstPayPeriodOfTheMonth(month) {
        let total = 0;
        let date = new Date(this.year, month - 1, END_OF_FIRST_PAY_PERIOD);
        let status = date < new Date() ? "Paid" : "";

        for (const transfer of this.stripeTransfers) {
            if (this.isInFirstPayPeriodTimespan(transfer, month)) {
                total += transfer.amount;
            }
        }

        return {
            total: total,
            status: status,
            date:
                date.toLocaleString("default", { month: "short" }) +
                " " +
                END_OF_FIRST_PAY_PERIOD +
                ", " +
                this.year,
        };
    }

    /**
     *
     * @param {object} transfer
     * @param {int} desiredMonth
     */
    isInFirstPayPeriodTimespan(transfer, desiredMonth) {
        let transferDate = new Date(transfer.created);
        let transferMonth = transferDate.getUTCMonth() + 1;
        let transferDay = transferDate.getUTCDate();
        let previousMonth = desiredMonth - 1;

        if (transferMonth === desiredMonth) {
            return transferDay <= END_OF_FIRST_PAY_PERIOD;
        }

        if (transferMonth === previousMonth) {
            return transferDay > START_OF_FIRST_PAY_PERIOD;
        }

        return false;
    }

    /**
     *
     * @param {int} month Numerical month 1-12
     */
    getSecondPayPeriodOfTheMonth(month) {
        let total = 0;
        let date = new Date(this.year, month - 1, END_OF_SECOND_PAY_PERIOD);
        let status = date < new Date() ? "Paid" : "";

        for (const transfer of this.stripeTransfers) {
            if (this.isInSecondPayPeriodTimespan(transfer, month)) {
                total += transfer.amount;
            }
        }

        return {
            total: total,
            status: status,
            date:
                date.toLocaleString("default", { month: "short" }) +
                " " +
                END_OF_SECOND_PAY_PERIOD +
                ", " +
                this.year,
        };
    }

    /**
     *
     * @param {object} transfer
     * @param {int} desiredMonth
     */
    isInSecondPayPeriodTimespan(transfer, desiredMonth) {
        let transferDate = new Date(transfer.created);
        let transferMonth = transferDate.getUTCMonth() + 1;
        let transferDay = transferDate.getUTCDate();
        let nextMonth = desiredMonth + 1;

        if (transferMonth === desiredMonth) {
            return transferDay > START_OF_SECOND_PAY_PERIOD;
        }

        if (transferMonth === nextMonth) {
            return transferDay <= END_OF_SECOND_PAY_PERIOD;
        }

        return false;
    }
}
