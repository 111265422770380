// Packages
import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
// Components
import { Icon, Toast } from 'antd-mobile';
// Styles
import './StripeBridge.scss';

@inject('store')
@observer
class StripeBridge extends React.Component {
  @observable connectLoading = true;
  @observable dashLoading = true;
  @observable connectDisabled = false;
  @observable dashDisabled = false;
  @observable stripeConnectUrl = '';
  @observable stripeDashUrl = '';

  componentDidMount() {
    this.props.store.Account.getStripeConnectUrl().then((stripeConnectUrl) => {
      this.connectLoading = false;
      
      if (stripeConnectUrl) {
        this.stripeConnectUrl = stripeConnectUrl;
      } else {
        this.connectDisabled = true;
        Toast.fail('Error retrieving Stripe Connect url', 1.5);
      }
    });

    this.props.store.Account.getStripeDashboardUrl().then((stripeDashUrl) => {
      this.dashLoading = false;
      
      if (stripeDashUrl) {
        this.stripeDashUrl = stripeDashUrl;
      } else {
        this.dashDisabled = true;
      }
    });
  }

  render() {
    return (
      <div className="auth-page setup-stripe-page">
        <div className="auth-logo-wrapper">
          <img src="/assets/logo-white.png" alt="GF Crew" className="auth-logo" />
        </div>

        <div className="auth-explainer text-center">
          <img src="/assets/money.svg" alt="money" />
          <p>We use Stripe to make sure you get paid on time and to keep your personal bank and details secure.</p>
          <p>Open Stripe in a browser below to sign up or edit settings.</p>
        </div>

        <div className="buttons">
          {!this.connectDisabled && this.dashDisabled &&
            <a 
              href={this.stripeConnectUrl}
              className="am-button am-button-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.connectLoading ? <Icon type="loading" /> : 'SETUP STRIPE'}
            </a>
          }
        </div>

        <div className="buttons">
          {!this.dashDisabled &&
            <a 
              href={this.stripeDashUrl}
              className="am-button am-button-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.connectLoading ? <Icon type="loading" /> : 'LAUNCH STRIPE'}
            </a>
          }
        </div>
      </div>
    );
  }
}

export default StripeBridge;
