import { observable, computed } from "mobx";
import UserStore from "./user";
import AccountStore from "./account";
import JobStore from "./job";
import CustomerStore from "./customer";
import ChargeStore from "./charge";
import MomemetStore from "./moment";

export default class RootStore {
    constructor() {
        this.User = new UserStore(this);
        this.Account = new AccountStore(this);
        this.Job = new JobStore(this);
        this.Customer = new CustomerStore(this);
        this.Charge = new ChargeStore(this);
        this.Moments = new MomemetStore(this);
    }

    @observable
    baseUrl = process.env.REACT_APP_API_URL;

    @observable
    firebaseUrl = process.env.REACT_APP_FIREBASE_URL;

    @computed
    get token() {
        if (this.User.user.token) {
            return this.User.user.token;
        } else {
            return localStorage.getItem("capturelife-token");
        }
    }

    @computed
    get authHeaders() {
        if (this.User.user.token) {
            return { Authorization: `Token ${this.User.user.token}` };
        } else {
            return {
                Authorization: `Token ${localStorage.getItem(
                    "capturelife-token"
                )}`,
            };
        }
    }
}
