// Packages
import React from "react";
import { inject, observer } from "mobx-react";
import PreSignUp from "../../modules/presignup";
import Validate from "../../modules/validator";
import { InputItem, Button, Toast, Icon, Checkbox } from "antd-mobile";
import JobUtil from "../../modules/jobUtilities";
import { Modal } from "antd-mobile";


const AgreeItem = Checkbox.AgreeItem;

@inject("store")
@observer
class RegisterCustomer extends React.Component {
    state = {
        customer: {},
        loading: false,
        agreed: false,
    };
    updateLoading(state) {
        this.setState({ loading: state });
    }

    async handleSubmit() {

        //Check the left spots
        
        this.updateLoading(true);
        let job = await this.getJob();
        console.log(job);

        let paidCustomers = job.charged / job.default_fee;
        if (job.max_registration <= paidCustomers) {
            Modal.alert(
                "Spot Unavailable",
                "Oops! Sorry, that spot is no longer available. Please contact me to double check.",
                [
                    {
                        text: "Go Back",
                        onPress: () => {
                            this.props.history.push(
                                "/register/" + job.account_id
                            );
                        },
                    },
                ]
            );
        }

        let presignup = new PreSignUp();
        let jobId = this.getJobid();
        let customerName = document.getElementById("customer_name").value;
        let customerEmail = document.getElementById("customer_email").value;
        let customerPhone = document.getElementById("customer_phone").value;
        let playerFirstName = document.getElementById("player_first_name")
            .value;
        let playerLastName = document.getElementById("player_last_name").value;
        let playerTeam = document.getElementById("player_team").value;
        let playerJerseyNumber = document.getElementById("player_jersey_number")
            .value;
        let playerAge = document.getElementById("player_age").value;
        let playerPosition = document.getElementById("player_position").value;

        if (
            customerName.length === 0 ||
            customerEmail.length === 0 ||
            customerPhone.length === 0 ||
            playerFirstName.length === 0 ||
            playerLastName.length === 0 ||
            playerTeam.length === 0 ||
            playerJerseyNumber.length === 0 ||
            playerAge.length === 0
        ) {
            this.updateLoading(false);
            Toast.fail("All fields are required.", 1.5);
            return false;
        }

        if (!Validate.email(customerEmail)) {
            Toast.fail("Please enter a valid email for your customer", 2);
            this.updateLoading(false);
            return false;
        }

        let customerCreated = await presignup.createCustomer(jobId, {
            name: customerName,
            email: customerEmail,
            phone: customerPhone,
        });

        presignup.storeLocally(
            "player",
            JSON.stringify({
                first_name: playerFirstName,
                last_name: playerLastName,
                team: playerTeam,
                number: playerJerseyNumber,
                notes: playerAge,
                position: playerPosition,
            })
        );

        if (customerCreated) {
            // Go to payment page.
            this.props.history.push("/register/pay/" + jobId);
        }
        this.updateLoading(false);
    }

    getJobid() {
        return this.props.match.params.id;
    }

    // getJob() {
    //     let jobs = JSON.parse(localStorage.getItem("jobs"));
    //     if (jobs) {
    //         let selectedJob = jobs.filter((job) => job.id == this.getJobid());
    //         PreSignUp.setSelectedJob(JSON.stringify(selectedJob));
    //         this.setState({ job: selectedJob });
    //         return selectedJob[0];
    //     } else {
    //         return false;
    //     }
    // }

    async getJob() {

        let signup = new PreSignUp();
        let jobs = await signup.getJobs(localStorage.getItem('accountId'));

        if (jobs) {
            let selectedJob = jobs.filter((job) => job.id == this.getJobid());
            PreSignUp.setSelectedJob(JSON.stringify(selectedJob));
            this.setState({ job: selectedJob });
            return selectedJob[0];
        } else {
            return false;
        }
    }

    getJobFromStorage() {

        let jobs = JSON.parse(localStorage.getItem("jobs"));
        if (jobs) {
            let selectedJob = jobs.filter((job) => job.id == this.getJobid());
            PreSignUp.setSelectedJob(JSON.stringify(selectedJob));
            this.setState({ job: selectedJob });
            return selectedJob[0];
        } else {
            return false;
        }
    }

    componentDidMount() {
        let job = this.getJobFromStorage();

        if (!job) {
            PreSignUp.redirectToRegister();
        }

        console.log(job.charged, job.default_fee);
        let paidCustomers = job.charged / job.default_fee;

        if (job.max_registration <= paidCustomers) {
            this.props.history.push("/register/" + job.account_id);
            Toast.fail("This event no longer has any available spots.", 2);
        }
    }
    render() {
        return (
            <div className="new-customer-page">
                <div className="text-center">
                    <h2>Customer Information</h2>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <InputItem clear placeholder="Name" id="customer_name">
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>
                    <br />
                    <InputItem clear placeholder="Email" id="customer_email">
                        <img src="/assets/envelope.svg" alt="envelope" />
                    </InputItem>
                    <br />
                    <InputItem clear placeholder="Phone" id="customer_phone">
                        <img src="/assets/phone.svg" alt="phone" />
                    </InputItem>
                    <div className="text-center">
                        <h2>Player Information</h2>
                        <p style={{ color: "#888" }}>ONE ENTRY PER PLAYER</p>
                    </div>
                    <InputItem
                        clear
                        placeholder="Player First Name"
                        id="player_first_name"
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>
                    <br />
                    <InputItem
                        clear
                        placeholder="Player Last Name"
                        id="player_last_name"
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>
                    <br />
                    <InputItem clear placeholder="Team" id="player_team">
                        <img src="/assets/jersey.svg" alt="jersey" />
                    </InputItem>
                    <br />
                    <InputItem
                        clear
                        placeholder="Jersey #"
                        id="player_jersey_number"
                    >
                        <img src="/assets/number.svg" alt="number" />
                    </InputItem>
                    <br />
                    <InputItem clear placeholder="Age / Div." id="player_age">
                        <img src="/assets/nametag.svg" alt="nametag" />
                    </InputItem>
                    <br />
                    <InputItem
                        placeholder="Notes / Position"
                        id="player_position"
                    >
                        <img src="/assets/info.svg" alt="position" />
                    </InputItem>
                    <div className="text-center">
                        <h2>Agreement</h2>
                    </div>
                    <AgreeItem
                        data-seed="logId"
                        onChange={(e) =>
                            this.setState({ agreed: e.target.checked })
                        }
                    >
                        I agree to use these photos for personal use only unless
                        expressly stated otherwise by the photographer.
                    </AgreeItem>
                </form>

                <div className="flex-center">
                    <Button
                        type="primary"
                        onClick={this.handleSubmit.bind(this)}
                        className="gf-button"
                        disabled={!this.state.agreed}
                    >
                        {this.state.loading ? (
                            <Icon type="loading" />
                        ) : (
                            "MAKE PAYMENT"
                        )}
                    </Button>
                </div>
            </div>
        );
    }
}

export default RegisterCustomer;
