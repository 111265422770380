import React from "react";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";

@inject("store")
class ViewMomentsBtn extends React.Component {
    state = {
        isMoments: false,
    };
    async componentDidMount() {
        let moments = await this.props.store.Moments.getMoments(
            this.props.jobId
        );
        this.setState({ isMoments: moments.length });
    }

    getMomentBtn() {
        return (
            <Link
                to={"/moments/" + this.props.jobId}
                className="block-button am-button am-button-primar clickable"
            >
                <img
                    src="/assets/image.svg"
                    className="inline-icon"
                    alt="Photos"
                    height="18px"
                />
                VIEW MOMENTS
            </Link>
        );
    }

    render() {
        if (this.state.isMoments) {
            return this.getMomentBtn();
        } else {
            return <div></div>;
        }
    }
}
export default ViewMomentsBtn;
