// Packages
import React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { InputItem, Button, Toast, Icon, Checkbox } from "antd-mobile";
import { Link } from "react-router-dom";
import Validate from "../../modules/validator";
import Zoho from "../../modules/Zoho";
// Styles
import "./SignUp.scss";

const CheckboxItem = Checkbox.CheckboxItem;

@inject("store")
@observer
class SignUp extends React.Component {
    @observable
    terms = false;

    @observable
    loading = false;

    @observable
    name = "";

    @observable
    email = "";

    @observable
    phone = "";

    @observable
    password = "";

    @observable
    confirmPassword = "";

    @action.bound
    handleChange = (key, event) => {
        switch (key) {
            case "name":
                this.name = event;
                break;
            case "email":
                this.email = event;
                break;
            case "phone":
                this.phone = event;
                break;
            case "password":
                this.password = event;
                break;
            case "confirmPassword":
                this.confirmPassword = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    handleSubmit = async () => {
        this.loading = true;

        if (!Validate.email(this.email)) {
            Toast.fail("You must provide a valid email address", 1.5);
            this.loading = false;
            return;
        }

        if (!Validate.phone(this.phone)) {
            Toast.fail("The phone number must be a 10 digit number.", 2.5);
            this.loading = false;
            return;
        }

        if (this.terms === false) {
            Toast.fail("You must accept the terms of use", 1.5);
            this.loading = false;
            return;
        }

        if (this.password !== this.confirmPassword) {
            Toast.fail("Passwords do not match", 1.5);
            this.loading = false;
        } else {
            const resError = await this.props.store.User.signUp(
                this.name,
                this.email,
                this.password,
                this.phone
            );
            this.loading = false;
            if (resError) {
                Toast.fail(resError, 1.5);
            } else {
                this.props.history.push("/accounts/new");
            }
        }
    };

    componentDidMount() {
        Zoho.hideZoho();
    }

    render() {
        return (
            <div className="auth-page sign-up-page">
                <div className="auth-logo-wrapper">
                    <img
                        src="/assets/logo-white.png"
                        alt="GF Crew"
                        className="auth-logo"
                    />
                </div>

                <form onSubmit={this.handleSubmit}>
                    <InputItem
                        clear
                        placeholder="Name"
                        value={this.name}
                        onChange={(event) => this.handleChange("name", event)}
                    >
                        <img src="/assets/user.svg" alt="envelope" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Email"
                        type="email"
                        value={this.email}
                        onChange={(event) => this.handleChange("email", event)}
                    >
                        <img src="/assets/envelope.svg" alt="envelope" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Mobile Phone"
                        value={this.phone}
                        onChange={(event) => this.handleChange("phone", event)}
                    >
                        <img src="/assets/phone.svg" alt="envelope" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        type="password"
                        placeholder="Password"
                        value={this.password}
                        onChange={(event) =>
                            this.handleChange("password", event)
                        }
                    >
                        <img src="/assets/lock.svg" alt="lock" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        type="password"
                        placeholder="Retype Password"
                        value={this.confirmPassword}
                        onChange={(event) =>
                            this.handleChange("confirmPassword", event)
                        }
                    >
                        <img src="/assets/lock.svg" alt="lock" />
                    </InputItem>
                    <br />
                    <CheckboxItem onChange={() => (this.terms = !this.terms)}>
                        <span className="terms-of-use-text">
                            I read and accept the{" "}
                            <a
                                href="https://www.capturelife.com/terms/"
                                rel="noopener"
                                target="_blank"
                            >
                                <u>TERMS OF USE</u>
                            </a>
                        </span>
                    </CheckboxItem>
                </form>

                <div className="buttons">
                    <Button type="warning" onClick={this.handleSubmit}>
                        {this.loading ? <Icon type="loading" /> : "SIGN UP"}
                    </Button>
                </div>
                <div className="text-center">
                    <Link to="/sign-in" className="sign-in-link">
                        Already have an account? Sign in
                    </Link>
                </div>
            </div>
        );
    }
}

export default SignUp;
