import React from "react";
import { WingBlank, Icon, WhiteSpace } from "antd-mobile";

class Loading extends React.Component {
    render() {
        return (
            this.props.loading && (
                <div>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <WingBlank style={{ textAlign: "center" }}>
                        <Icon type="loading" />
                        <p>Fetching Data...</p>
                    </WingBlank>
                </div>
            )
        );
    }
}
export default Loading;
