import { observable, action } from "mobx";
import axios from "axios";
import ZapierHooks from "../modules/ZapierHooks";
import CaptureLifeApi from "../modules/CaptureLifeApi";

export default class ChargeStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    // Observables
    @observable
    chargesUrl = `${this.rootStore.baseUrl}/portal/charges?cl_events=true`;

    @observable
    refundUrl = `${this.rootStore.baseUrl}/portal/refunds?cl_events=true`;

    @observable
    firebaseUrl = `${this.rootStore.firebaseUrl}`;

    getApi() {
        if (this.api instanceof CaptureLifeApi) {
            return this.api;
        } else {
            this.api = new CaptureLifeApi(this.rootStore.token);
            return this.api;
        }
    }

    // Actions
    @action.bound
    async createCharge(amount, charge_token, customer_id) {
        amount = Number(amount) * 100;

        try {
            let res = await axios.post(
                this.chargesUrl,
                { amount, charge_token, customer_id },
                { headers: this.rootStore.authHeaders }
            );

            return {
                success: true,
                data: res.data,
            };
        } catch (err) {
            return {
                success: false,
                message: err.response.data.error,
            };
        }
    }

    // Actions
    @action.bound
    async processCardOcr(base64) {
        let error;
        const res = await axios
            .post(
                `${this.firebaseUrl}/processCardImg`,
                { image: base64 },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .catch((err) => {
                error = err.response.data.error.message;
            });

        if (error) {
            return { err: error };
        } else {
            return res;
        }
    }

    @action.bound
    async refundCharge(chargeId, amount) {
        let error;
        let res = await axios
            .post(
                this.refundUrl,
                {
                    charge_id: chargeId,
                    amount: amount,
                },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                console.log(err);
                error = err;
            });

        ZapierHooks.recordRefundWebHook(chargeId);

        if (error) {
            console.log(error);
            return { err: error.response.data.err };
        } else {
            console.log(res);
            return res;
        }
    }

    getCharge(customerId) {
        return this.getApi().getCharge(customerId);
    }

    getAccountCharges(accountId) {
        return this.getApi().getAccountCharges(accountId, 1);
    }
}
