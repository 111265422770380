// Packages
import React from "react";
// Components
import { inject } from "mobx-react";
import { Elements } from "react-stripe-elements";
import CaptureLifeApi from "../../modules/CaptureLifeApi";
import ChargeForm from "./ChargeForm";
// Styles
import "./ChargeNew.scss";
import { Link } from "react-router-dom";

@inject("store")
class ChargeNew extends React.Component {
    state = {
        isStripeVerified: true,
    };

    getAccountId() {
        let accountId = this.props.store.Job.activeJob.account_id;

        if (accountId) {
            return accountId;
        }

        let activeJob = JSON.parse(sessionStorage.getItem("activeJob"));
        if (!activeJob) {
            return false;
        } else {
            return activeJob.account_id;
        }
    }

    getLogo() {
        let api = new CaptureLifeApi();
        let accountId = this.getAccountId();
        if (accountId) {
            return api.getLogoUrlForAccount(accountId);
        } else {
            return "/assets/image.svg";
        }
    }

    getExplainerContent() {
        if (this.state.isStripeVerified) {
            return (
                <div>
                    <p>Please do not refresh or exit the page.</p>
                    <p>
                        Your new customer and player data could be lost if you
                        leave before taking payment.
                    </p>
                </div>
            );
        } else {
            return (
                <p>
                    <img
                        src="/assets/info.svg"
                        className="inline-icon"
                        alt="Photos"
                        height="20px"
                    />
                    You must setup your stripe account to take payments. For
                    more infomation <Link to="/setup-stripe">Click Here</Link>
                </p>
            );
        }
    }

    async componentDidMount() {
        this.setState({
            isStripeVerified: await this.props.store.Account.isAccountsStripeVerified(),
        });
        console.log(this.state.isStripeVerified);
    }

    render() {
        return (
            <div className="payment-page new-charge-page">
                <div className="payment-logo-wrapper">
                    <img
                        src={this.getLogo()}
                        alt="landscape"
                        style={{
                            height: "150px",
                        }}
                        className="payment-logo"
                    />
                </div>

                <div className="payment-explainer text-center">
                    {this.getExplainerContent()}
                </div>

                <Elements>
                    <ChargeForm />
                </Elements>
            </div>
        );
    }
}

export default ChargeNew;
