import axios from "axios";
export default class ZapierHooks {
    static recordRegisterWebHook(params) {
        let url = "https://hooks.zapier.com/hooks/catch/1347671/oq3j64d/";
        axios.get(url, { params: params });
    }

    static recordRefundWebHook(chargeId) {
        let url = "https://hooks.zapier.com/hooks/catch/1347671/oqf8w93/";
        axios.get(url, { params: { transactionID: "" + chargeId } });
    }
}
