import axios from "axios";
import Settings from "./settings";

export default class CaptureLifeApi {
    baseUrl = process.env.REACT_APP_API_URL;
    token = null;
    axios = null;

    constructor(token) {
        this.token = token;
        this.axios = axios.create({
            baseURL: this.baseUrl,
            headers: this.getAuthHeaders(),
        });
    }

    getAuthHeaders() {
        return { Authorization: `Token ${this.token}` };
    }

    /**
     * Logo Endpoints
     */

    getLogoUrlForAccount(accountId) {
        return this.baseUrl + "/accounts/" + accountId + "/logo";
    }

    /**
     * Customer Endpoints
     */
    createNewCustomer(customerData) {
        let url = this.baseUrl + "/portal/customers?cl_events=true";
        return this.axios.post(url, customerData);
    }

    updateCustomer(customerId, attributes) {
        let url = `${this.baseUrl}/portal/customers/${customerId}?cl_events=true`;
        return this.axios.put(url, attributes);
    }

    getCustomers(jobId) {
        let url = "/portal/customers?cl_events=true&job_id=" + jobId;
        return this.axios.get(url);
    }

    getCustomersByAccount(accountId){
        let url = "/portal/customers?cl_events=true&page=1&per_page=100";
    }

    /**
     * Player Endpoints
     */

    createNewPlayer(playerData) {
        let url = this.baseUrl + "/portal/subjects?cl_events=true";
        return this.axios.post(url, playerData);
    }

    getPlayersForJob(jobId) {
        let url =
            this.baseUrl + "/portal/subjects?cl_events=true&job_id=" + jobId;
        return this.axios.get(url);
    }

    getPlayersForCustomer(customerId) {
        let url =
            this.baseUrl +
            "/portal/subjects?cl_events=true&customer_id=" +
            customerId;
        return this.axios.get(url);
    }

    /**
     * Job Endpoints
     */

    getJobsForAccount(id) {
        let url = "/portal/jobs?cl_events=true&account_id=" + id;
        return this.axios.get(url);
    }

    deleteJob(jobId) {
        let url = "/portal/jobs/" + jobId + "?cl_events=true";
        return this.axios.delete(url);
    }

    /**
     * Account Endpoints
     */

    createAccount(name, website, logo_url) {
        let url = "/accounts?cl_events=true";
        return this.axios.post(url, { name, website, logo_url, logo_tyep: 1 });
    }

    getAccount(accountId) {
        let url = "https://api.capturelife.com/accounts?cl_events=true";
        return this.axios.get(url);
    }

    async getAccountSetting(accountId) {
        let url = `${this.baseUrl}/gfcrew_settings?account_id=` + accountId;
        let response = await this.axios.get(url);
        this.settings = new Settings(response.data.data);
        return this.settings;
    }

    async setAccountSettings(settings, accountId) {
        console.log(settings);
        let url = `${this.baseUrl}/gfcrew_settings?account_id=` + accountId;
        let response = await this.axios.put(url, settings.getSettings());
        this.settings = new Settings(response.data);
        return this.settings;
    }

    /**
     * Moments Endpoints
     */
    getMoments(jobId) {
        let url = "/portal/jobs/:id/moments".replace(":id", jobId);
        return this.axios.get(url);
    }

    /**
     * Charge Endpoints
     */
    getCharge(customerId) {
        let url = "/portal/charges?customer_id=" + customerId;
        return this.axios.get(url);
    }

    getAccountCharges(id, page) {
        let url = "/accounts/:account_id/stripe_transfers".replace(
            ":account_id",
            id
        );
        url += "?page=" + page;
        url += "&per_page=" + 120;
        return this.axios.get(url);
    }
}
