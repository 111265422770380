import React from "react";
import { Card, Icon } from "antd-mobile";
import { Link } from "react-router-dom";
import PreRegisterLinkBtn from "../../compoents/buttons/PreRegisterLinkBtn";

class JobCard extends React.Component {
    getDetailFooter(job) {
        return (
            <Card.Footer
                content={
                    <>
                        <img
                            src="/assets/user-warning.svg"
                            className="inline-icon"
                            alt="user"
                        />
                        <span>{job.customer_count} customer</span>
                        {Number(job.customer_count) === 1 ? null : (
                            <span>s</span>
                        )}
                    </>
                }
                extra={
                    <>
                        <img
                            src="/assets/jersey-warning.svg"
                            className="inline-icon"
                            alt="jersey"
                        />
                        <span>{job.subject_count} player</span>
                        {Number(job.customer_count) === 1 ? null : (
                            <span>s</span>
                        )}
                    </>
                }
            />
        );
    }
    getEditFooter(job) {
        return (
            <Card.Footer
                content={
                    <Link to={`/jobs/${job.id}/edit`}>
                        <img
                            src="/assets/edit-button.svg"
                            className="inline-icon"
                            alt="edit button"
                        />
                        <span className="primary-text">Edit</span>
                    </Link>
                }
                extra={
                    <>
                        <img
                            src="/assets/cal-primary.svg"
                            className="inline-icon"
                            alt="calendar"
                        />
                        <span className="primary-text">
                            {job.start_date
                                ? job.start_date.substring(0, 10)
                                : "No start date found"}
                        </span>
                    </>
                }
            />
        );
    }

    render() {
        let job = this.props.job;
        return (
            <Card className="job-card" key={job.id}>
                <Card.Header
                    title={
                        job.name ? (
                            <span>
                                {job.name}{" "}
                                <PreRegisterLinkBtn
                                    pre_signup={job.pre_signup}
                                    account_id={job.account_id}
                                />{" "}
                            </span>
                        ) : (
                            "No job name found"
                        )
                    }
                    extra={
                        <span className="warning-badge badge">
                            ${job.charged / 100}
                        </span>
                    }
                />
                <Card.Body>
                    <>
                        <img
                            src="/assets/cal-primary.svg"
                            className="inline-icon"
                            alt="calendar"
                        />
                        <span>
                            {job.start_date
                                ? job.start_date.substring(0, 10)
                                : "No start date found"}
                        </span>
                        <Link to={`/jobs/${job.id}`}>
                            {this.props.edit !== true && (
                                <Icon
                                    type="right"
                                    className="clickable job-detail-button primary-text"
                                />
                            )}
                        </Link>
                        <br />
                    </>
                </Card.Body>
                {this.props.edit === true
                    ? this.getEditFooter(job)
                    : this.getDetailFooter(job)}
            </Card>
        );
    }
}
export default JobCard;
