// Packages
import React from "react";
// Components
import { List } from "antd-mobile";
import GfcrewUpdates from "../../modules/GfcrewUpdates";

const Item = List.Item;
const Brief = Item.Brief;

class AppUpdates extends React.Component {
    state = {
        updates: {
            date: null,
            version: null,
            updates: null,
        },
        updatesModal: false,
    };

    getCurrentVersion() {
        return this.state.updates.version;
    }

    getDate() {
        return this.state.updates.date;
    }

    getUpdates() {
        return this.state.updates.updates;
    }

    async componentDidMount() {
        this.setState({
            updates: await GfcrewUpdates.getUpdatesFromServer(),
        });
    }

    getUpdateBreif(update) {
        return update.substring(update.indexOf(":") + 1);
    }

    getUpdateType(update) {
        return update.substring(0, update.indexOf(":"));
    }

    getIcon(update) {
        let type = this.getUpdateType(update);
        if (type.toLowerCase() === "feature") {
            return "/assets/new.svg";
        } else {
            return "/assets/wrench.svg";
        }
    }

    render() {
        return (
            <div>
                <List
                    renderHeader={() =>
                        "Version " +
                        this.getCurrentVersion() +
                        " application updates. Released " +
                        this.getDate()
                    }
                    className="my-list"
                >
                    {this.state.updates.version &&
                        this.state.updates.updates.map((update, index) => {
                            return (
                                <Item
                                    key={index}
                                    wrap={true}
                                    align="top"
                                    thumb={this.getIcon(update)}
                                    multipleLine={true}
                                >
                                    <Brief>{this.getUpdateType(update)}</Brief>
                                    {this.getUpdateBreif(update)}
                                </Item>
                            );
                        })}
                </List>
            </div>
        );
    }
}

export default AppUpdates;
