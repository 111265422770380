// Packages
import React from "react";
import Validate from "../../modules/validator";
import { action, observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { InputItem, Button, Toast, Icon } from "antd-mobile";
import MoveCustomerBtn from "../../compoents/buttons/MoveCustomerBtn";
// Styles
import "./CustomerEdit.scss";

@inject("store")
@observer
class CustomerEdit extends React.Component {
    @observable
    loading = false;

    @observable
    customer = {
        name: "",
        email: "",
        phone: "",
    };

    @computed get activeCustomer() {
        return this.props.store.Customer.activeCustomer;
    }

    @computed get customerId() {
        return Number(this.props.match.params.id);
    }

    @action.bound
    handleChange = (key, event) => {
        switch (key) {
            case "name":
                this.customer.name = event;
                break;
            case "email":
                this.customer.email = event;
                break;
            case "phone":
                this.customer.phone = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    handleSubmit = async () => {
        this.loading = true;
        let resError;

        if (
            this.customer.email.length < 1 ||
            !Validate.email(this.customer.email)
        ) {
            Toast.fail("Please enter a valid email for your customer", 2);
            this.loading = false;
        } else {
            resError = await this.props.store.Customer.updateCustomer(
                this.customer,
                this.customerId
            );
            this.loading = false;

            if (resError) {
                Toast.fail(resError, 1.5);
            } else {
                Toast.success("Customer updated", 1.5);
                this.props.history.push(
                    `/customers/${this.props.store.Customer.activeCustomer.id}`
                );
            }
        }
    };

    @action.bound
    assignCustomer(customer) {
        this.customer.name = customer.name;
        this.customer.email = customer.email;
        this.customer.phone = customer.phone;
    }

    componentDidMount() {
        if (
            !this.activeCustomer ||
            this.activeCustomer.id !== this.customerId
        ) {
            this.loading = true;
            this.props.store.Customer.getCustomer(this.customerId).then(
                (customer) => {
                    this.loading = false;
                    this.assignCustomer(customer);
                }
            );
        } else {
            this.assignCustomer(this.activeCustomer);
        }
    }

    render() {
        return (
            <div className="new-customer-page">
                <div className="text-center">
                    <h2>Edit Customer</h2>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <InputItem
                        clear
                        placeholder="Name"
                        value={this.customer.name}
                        onChange={(event) => this.handleChange("name", event)}
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Email"
                        value={this.customer.email}
                        onChange={(event) => this.handleChange("email", event)}
                    >
                        <img src="/assets/envelope.svg" alt="envelope" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Phone"
                        value={this.customer.phone}
                        onChange={(event) => this.handleChange("phone", event)}
                    >
                        <img src="/assets/phone.svg" alt="phone" />
                    </InputItem>
                    <br />
                    <div className="flex-center">
                        <Button
                            type="primary"
                            onClick={this.handleSubmit}
                            className="block-button am-button am-button-primary clickable"
                        >
                            {this.loading ? <Icon type="loading" /> : "SAVE"}
                        </Button>
                    </div>
                    <div className="flex-center">
                        <MoveCustomerBtn history={this.props.history} />
                    </div>
                </form>
            </div>
        );
    }
}

export default CustomerEdit;
