// Packages
import React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { InputItem, Button, Toast, Icon, ImagePicker } from "antd-mobile";
// Styles
import "./AccountNew.scss";

@inject("store")
@observer
class AccountNew extends React.Component {
    @observable
    loading = false;

    @observable
    name = "";

    @observable
    website = "";

    @observable
    files = [];

    @action.bound
    async uploadLogo() {
        const uploadUrl = await this.props.store.Account.getLogoUploadUrl(
            this.files[0].file.type
        );
        if (uploadUrl && this.files.length > 0) {
            const resError = await this.props.store.Account.uploadLogo(
                uploadUrl,
                this.files[0]
            );
            if (resError) {
                Toast.fail(resError, 1.5);
            }
        } else {
            Toast.fail("Image upload failed", 1.5);
        }
    }

    @action.bound
    handleImageSelect = (files) => {
        this.files = files;
    };

    @action.bound
    handleChange = (key, event) => {
        switch (key) {
            case "name":
                this.name = event;
                break;
            case "website":
                this.website = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    handleSubmit = async () => {
        this.loading = true;
        if (
            this.files.length > 0 &&
            this.files[0].file.type !== "image/jpeg" &&
            this.files[0].file.type !== "image/png"
        ) {
            Toast.fail("Logo must be a jpeg or png file", 2.5);
            this.loading = false;
            return;
        }

        let resError = await this.props.store.Account.createAccount(
            this.name,
            this.website
        );

        this.loading = false;
        if (resError) {
            Toast.fail(resError, 2);
        } else {
            if (this.files.length > 0) await this.uploadLogo();
            this.props.history.push("/setup-stripe");
        }
    };

    render() {
        return (
            <div className="auth-page new-account-page">
                <div className="auth-logo-wrapper">
                    <img
                        src="/assets/logo-white.png"
                        alt="GF Crew"
                        className="auth-logo"
                    />
                </div>

                <div className="auth-explainer text-center">
                    <p>Let's add some info about your company.</p>
                    <p>
                        Your company name, logo and web site will be used in all
                        email and text message communications with your
                        customers.
                    </p>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <InputItem
                        clear
                        placeholder="Company Name"
                        value={this.name}
                        onChange={(event) => this.handleChange("name", event)}
                    >
                        <img src="/assets/info.svg" alt="envelope" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Company Website"
                        value={this.website}
                        onChange={(event) =>
                            this.handleChange("website", event)
                        }
                    >
                        <img src="/assets/www.svg" alt="internet" />
                    </InputItem>
                </form>

                <div className="image-upload-form">
                    <p className="logo-label">
                        <img src="/assets/camera.svg" alt="camera" />
                        <span>Company Logo (optional)</span>
                    </p>

                    <ImagePicker
                        files={this.files}
                        onChange={this.handleImageSelect}
                        selectable={this.files.length < 1}
                        multiple={false}
                        length="1"
                    />
                </div>

                <div className="buttons">
                    <Button
                        type="warning"
                        onClick={this.handleSubmit}
                        disabled={this.name.length < 1}
                    >
                        {this.loading ? <Icon type="loading" /> : "NEXT"}
                    </Button>
                </div>
            </div>
        );
    }
}

export default AccountNew;
