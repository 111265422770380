import React from "react";
import { Button, Modal, Toast } from "antd-mobile";
import { inject } from "mobx-react";

const alert = Modal.alert;

@inject("store")
class DeleteJobBtn extends React.Component {
    async doesJobHavePaidCustomers() {
        try {
            let customers = await this.props.store.Customer.getCustomers();
            return customers.length > 0;
        } catch (error) {
            return true;
        }
    }

    async deleteJob() {
        if (await this.doesJobHavePaidCustomers()) {
            Toast.fail("Unable to delete a job with customers.", 2);
        } else {
            this.props.store.Job.deleteJob(this.props.jobId);
            Toast.success("Job has been deleted.", 2);
            setTimeout(() => {
                window.location = "/";
            }, 1000);
        }
    }
    render() {
        return (
            <Button
                className="block-button am-button am-button-warning clickable"
                onClick={() =>
                    alert("Deleting a job cannot be undone!", "Are you sure?", [
                        {
                            text: "Cancel",
                            onPress: () => console.log("cancel"),
                        },
                        { text: "Ok", onPress: () => this.deleteJob() },
                    ])
                }
            >
                DELETE THIS JOB
            </Button>
        );
    }
}
export default DeleteJobBtn;
