// Packages
import React from "react";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import Gallery from "react-photo-gallery";
// Components
import { Button, Modal, List, Toast, Flex } from "antd-mobile";
import Hammer from "hammerjs";
import "./Moments.scss";

@inject("store")
@observer
class Moments extends React.Component {
    state = {
        urls: [],
        activeImage: "",
        activeImageIndex: null,
    };

    @observable
    loading = false;

    getJobId() {
        return this.props.match.params.id;
    }

    async getMomentImageUrl(momentId) {
        let image = await this.props.store.Moments.getMomentImageUrl(
            momentId,
            false
        );
        return image.url;
    }

    setActiveImageIndex(index) {
        this.setState({
            activeImageIndex: index,
        });
    }

    async setActiveImage(key, id) {
        let image = await this.getMomentImageUrl(id);
        this.setState({
            activeImage: image,
            [key]: true,
        });
    }

    getNextIndex() {
        let nextIndex = this.state.activeImageIndex + 1;

        if (nextIndex > this.state.urls.length - 1) {
            nextIndex = 0;
        }

        return nextIndex;
    }

    getNextImage() {
        return this.state.urls[this.getNextIndex()];
    }

    getPreviousIndex() {
        let previousIndex = this.state.activeImageIndex - 1;

        if (previousIndex < 0) {
            previousIndex = this.state.urls.length - 1;
        }

        return previousIndex;
    }

    getPreviousImage() {
        return this.state.urls[this.getPreviousIndex()];
    }

    showModal = (key) => async (e, obj) => {
        await this.setActiveImage(key, obj.photo.id);
        this.setActiveImageIndex(obj.index);

        let myElement = document.getElementById("modal-image");
        let hammer = new Hammer(myElement);
        let thisMain = this;

        hammer.on("swiperight swipeleft", function (ev) {
            if (ev.type === "swiperight") {
                let previousImage = thisMain.getPreviousImage();
                thisMain.setActiveImage(key, previousImage.momentId);
                thisMain.setActiveImageIndex(thisMain.getPreviousIndex());
            }

            if (ev.type === "swipeleft") {
                let nextImage = thisMain.getNextImage();
                thisMain.setActiveImage(key, nextImage.momentId);
                thisMain.setActiveImageIndex(thisMain.getNextIndex());
            }
        });
    };

    onClose = (key) => () => {
        this.setState({
            [key]: false,
        });
    };

    async loadMoments() {
        this.loading = true;
        Toast.loading("Fetching images...", 20);
        let moments = await this.props.store.Moments.getThumbnailUrlsByJobId(
            this.getJobId()
        );
        Toast.hide();
        this.loading = false;
        return moments;
    }

    async componentDidMount() {
        let moments = await this.loadMoments();
        this.setState({ urls: moments });
    }

    getPhotos() {
        return this.state.urls.map((image, index) => {
            let gcd = this.getJobId(image.height, image.width);
            return {
                src: image.url,
                width: image.width / gcd,
                height: image.height / gcd,
                id: image.momentId,
            };
        });
    }

    getGreatestCommonDivisor(firstNum, secondNum) {
        if (!secondNum) {
            return firstNum;
        }
        return this.getGreatestCommonDivisor(secondNum, firstNum % secondNum);
    }

    render() {
        return (
            <>
                <Gallery
                    photos={this.getPhotos()}
                    onClick={this.showModal("modal1")}
                />
                <Modal
                    popup
                    visible={this.state.modal1}
                    onClose={this.onClose("modal1")}
                    animationType="slide-up"
                >
                    <List
                        renderHeader={() => <div>Full Resolution View</div>}
                        className="popup-list"
                    >
                        <Flex>
                            <Flex.Item>
                                <img
                                    id="modal-image"
                                    alt="High Resolution"
                                    src={this.state.activeImage}
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                    }}
                                />
                            </Flex.Item>
                        </Flex>

                        <List.Item>
                            <Button
                                type="primary"
                                onClick={this.onClose("modal1")}
                            >
                                Close
                            </Button>
                        </List.Item>
                    </List>
                </Modal>
            </>
        );
    }
}

export default Moments;
