// Packages
import React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { Icon, Toast } from "antd-mobile";
import { Link } from "react-router-dom";
// Styles
import "./StripeSetup.scss";

@inject("store")
@observer
class StripeSetup extends React.Component {
    @observable loading = true;
    @observable stripeUrl = "";
    @observable step = 1;

    componentDidMount() {
        this.props.store.Account.getStripeConnectUrl().then((stripeUrl) => {
            this.loading = false;

            if (stripeUrl) {
                this.stripeUrl = stripeUrl;
            } else {
                Toast.fail("Error retrieving Stripe Payments url", 1.5);
            }
        });

        const setupComplete = new URLSearchParams(
            this.props.location.search
        ).get("complete");
        if (setupComplete === "true") {
            this.step = 2;
        }
    }

    @action.bound
    nextStep() {
        this.step = 2;
    }

    render() {
        return (
            <div className="auth-page setup-stripe-page">
                <div className="auth-logo-wrapper">
                    <img
                        src="/assets/logo-white.png"
                        alt="GF Crew"
                        className="auth-logo"
                    />
                </div>

                {this.step === 1 && (
                    <>
                        <div className="auth-explainer text-center">
                            <img src="/assets/money.svg" alt="money" />
                            <p>
                                We need to know where to send the money you make
                                on your jobs. We use Stripe to make sure you get
                                paid on time and to keep your personal bank and
                                details secure.{" "}
                            </p>
                            <p>
                                There is a $5 + 3% service charge for each
                                customer you register. This covers unlimited
                                storage and maintenance of the app.
                            </p>
                        </div>

                        <div className="buttons">
                            <a
                                href={this.stripeUrl}
                                className="am-button am-button-warning"
                                onClick={this.nextStep}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {this.loading ? (
                                    <Icon type="loading" />
                                ) : (
                                    "SETUP PAYMENTS"
                                )}
                            </a>
                        </div>

                        <div className="skip-button-wrapper">
                            <Link
                                to="/"
                                className="am-button am-button-ghost clickable auth-ghost"
                            >
                                SKIP
                            </Link>
                        </div>
                    </>
                )}

                {this.step === 2 && (
                    <>
                        <div className="auth-explainer text-center">
                            <h2>All set?</h2>
                            <p>
                                When you're done connecting to CaptureLife on
                                Stripe, hit the button below to jump in!
                            </p>
                        </div>

                        <div className="buttons">
                            <Link
                                to="/"
                                className="am-button am-button-warning"
                            >
                                FINISH
                            </Link>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default StripeSetup;
