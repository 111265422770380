// Packages
import React from "react";
import { inject, observer } from "mobx-react";
import ChargeForm from "./ChargeForm";
import { Elements } from "react-stripe-elements";
import PreSignUp from "../../modules/presignup";
import JobUtil from "../../modules/jobUtilities";
import { Modal } from "antd-mobile";
// Components

@inject("store")
@observer
class PreSignupPay extends React.Component {
    getLogo() {
        let accountId = PreSignUp.getAccountId();
        if (accountId) {
            return PreSignUp.getLogoUrl(accountId);
        } else {
            return "/assets/image.svg";
        }
    }

    componentDidMount() {
        try {
            let job = PreSignUp.getSelectedJob()[0];
            console.log(JobUtil.getSpotsAvailable(job));
            if (JobUtil.getSpotsAvailable(job) === 0) {
                Modal.alert(
                    "Spot Unavailable",
                    "Oops! Sorry, that spot is no longer available. Please contact me to double check.",
                    [
                        {
                            text: "Go Back",
                            onPress: () => {
                                this.props.history.push(
                                    "/register/" + job.account_id
                                );
                            },
                        },
                    ]
                );
            }
        } catch (err) {}
    }
    render() {
        return (
            <div className="payment-page new-charge-page">
                <div className="payment-logo-wrapper">
                    <img
                        src={this.getLogo()}
                        alt="landscape"
                        style={{
                            height: "150px",
                        }}
                        className="payment-logo"
                    />
                </div>

                <Elements>
                    <ChargeForm />
                </Elements>
            </div>
        );
    }
}

export default PreSignupPay;
