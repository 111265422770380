import { observable, action } from "mobx";
import axios from "axios";
import CaptureLifeApi from "../modules/CaptureLifeApi";

export default class MomentStore {
    @observable
    jobMomentsUrl = `${this.rootStore.baseUrl}/portal/jobs/:id/moments`;

    @observable
    momentUrl = `${this.rootStore.baseUrl}/moment/url?id=:id`;

    @observable
    moments = {};

    @observable
    momentUrlSessionKey = "moments_:id_urls";

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    getApi() {
        if (this.api instanceof CaptureLifeApi) {
            return this.api;
        } else {
            this.api = new CaptureLifeApi(this.rootStore.token);
            return this.api;
        }
    }
    @action.bound
    async getMoments(jobId) {
        let res = await this.getApi().getMoments(jobId);
        return (this.moments = res.data);
    }

    @action.bound
    async getMomentThumbnailUrl(momentId) {
        let url = this.momentUrl.replace(":id", momentId);
        url += "&no_redirect=true";
        url += "&scale=t";
        let res = await axios.get(url, {
            headers: this.rootStore.authHeaders,
        });
        return res.data;
    }

    @action.bound
    async getMomentImageUrl(momentId) {
        let url = this.momentUrl.replace(":id", momentId);
        url += "&no_redirect=true";
        url += "&scale=f";
        let res = await axios.get(url, {
            headers: this.rootStore.authHeaders,
        });
        return res.data;
    }

    @action.bound
    async getFormatedUrlsAndIds(jobId) {
        let data = [];
        let moments = await this.getMoments(jobId);
        for (let i = 0; i < moments.length; i++) {
            let image = await this.getMomentThumbnailUrl(moments[i].id);
            data.push({
                url: image.url,
                momentId: moments[i].id,
                height: moments[i].height,
                width: moments[i].width,
            });
        }
        return data;
    }

    @action.bound
    async getThumbnailUrlsByJobId(jobId) {
        let data = [];
        let momentUrlsInSession = this.getMomentUrlsFromSession(jobId);

        if (momentUrlsInSession) {
            return momentUrlsInSession;
        } else {
            data = await this.getFormatedUrlsAndIds(jobId);
            this.setMomentUrlsInSession(jobId, data);
            return data;
        }
    }

    setMomentUrlsInSession(id, urls) {
        sessionStorage.setItem(
            this.getUniqueMomentSessionKey(id),
            JSON.stringify(urls)
        );
    }

    getMomentUrlsFromSession(id) {
        let momentUrls = sessionStorage.getItem(
            this.getUniqueMomentSessionKey(id)
        );
        return JSON.parse(momentUrls);
    }

    getUniqueMomentSessionKey(jobId) {
        return this.momentUrlSessionKey.replace(":id", jobId);
    }
}
