import { observable, action } from "mobx";
import axios from "axios";
import CaptureLifeApi from "../modules/CaptureLifeApi";

export default class CustomerStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable
    customersUrl = `${this.rootStore.baseUrl}/portal/customers?cl_events=true`;

    @observable
    playersUrl = `${this.rootStore.baseUrl}/portal/subjects?cl_events=true`;

    // Observables
    @observable
    customers = [];

    @observable
    activeCustomer = {};

    @observable
    players = [];

    @observable
    customerCharges = [];

    @observable
    activePlayer = {};

    @observable
    playerToCreate = null;

    getApi() {
        if (this.api instanceof CaptureLifeApi) {
            return this.api;
        } else {
            this.api = new CaptureLifeApi(this.rootStore.token);
            return this.api;
        }
    }

    // Customer Actions
    @action.bound
    async createCustomer(newCustomer) {
        if (!this.rootStore.Job.activeJob.id) console.error("No job found");

        let { name, email, phone } = newCustomer;
        const job_id = this.rootStore.Job.activeJob.id;

        let error;
        const res = await axios
            .post(
                this.customersUrl,
                { name, job_id, email, phone },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        } else {
            this.activeCustomer = res.data;
            this.setActiveCustomerSession();
            this.customers.push(res.data);
        }
    }

    @action.bound
    async deleteCustomer(customerId) {
        let error;
        let res = await axios
            .delete(
                `${this.rootStore.baseUrl}/portal/customers/${customerId}?cl_events=true`,
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err;
            });

        if (error) {
            return error;
        } else {
            this.customers = this.customers.filter((customer) => {
                return customer.id !== customerId;
            });
            return this.customers;
        }
    }

    @action.bound
    async getCustomers() {
        let url = this.customersUrl;
        if (this.rootStore.Job.activeJob.id)
            url += `&job_id=${this.rootStore.Job.activeJob.id}`;

        let error;
        const res = await axios
            .get(url, { headers: this.rootStore.authHeaders })
            .catch((err) => {
                error = err;
            });

        if (error) {
            return error;
        } else {
            this.customers = res.data;
            return this.customers;
        }
    }

    @action.bound
    async getAllCustomers() {
        let url = this.customersUrl;

        let error;
        const res = await axios
            .get(url, { headers: this.rootStore.authHeaders })
            .catch((err) => {
                error = err;
            });

        if (error) {
            return error;
        } else {
            this.customers = res.data;
            return this.customers;
        }
    }

    getCustomersByJobId(jobId) {
        return this.getApi().getCustomers(jobId);
    }

    @action.bound
    async getCustomer(id) {
        // No endpoint provided from backend
        // Attempt filter, fallback on API call + filter
        const customerFromFilter = this.customers.filter(
            (customer) => customer.id === id
        )[0];
        if (!customerFromFilter) {
            await this.getCustomers();
            this.activeCustomer = this.customers.filter(
                (customer) => customer.id === id
            )[0];
            this.setActiveCustomerSession();
            this.customerCharges = this.activeCustomer.charges;
            return this.activeCustomer;
        } else {
            this.activeCustomer = customerFromFilter;
            this.setActiveCustomerSession();
            this.customerCharges = this.activeCustomer.charges;
            return customerFromFilter;
        }
    }

    @action.bound
    async updateCustomer(customer, customerId) {
        const { name, email, phone } = customer;

        let error;
        const res = await axios
            .put(
                `${this.rootStore.baseUrl}/portal/customers/${customerId}?cl_events=true`,
                { name, email, phone },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        } else {
            this.activeCustomer = res.data;
            this.setActiveCustomerSession();
            this.customers = [];
        }
    }

    // Player Actions

    @action.bound
    async createPlayer(newPlayer) {
        if (!this.activeCustomer.id) console.error("No customer found");

        const {
            first_name,
            last_name,
            team,
            number,
            notes,
            position,
        } = newPlayer;
        const customer_id = this.activeCustomer.id;

        let error;
        const res = await axios
            .post(
                this.playersUrl,
                {
                    customer_id,
                    first_name,
                    last_name,
                    team,
                    number,
                    notes,
                    position,
                },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        } else if (res) {
            this.activePlayer = res.data;
            this.players.push(res.data);
        }
    }

    @action.bound
    async getPlayers(query) {
        let res = undefined;

        try {
            if (query === "job") {
                res = await this.getApi().getPlayersForJob(
                    this.rootStore.Job.activeJob.id
                );
            } else {
                res = await this.getApi().getPlayersForCustomer(
                    this.activeCustomer.id
                );
            }
            this.players = res.data;
        } catch (err) {
            this.players = [];
            console.log(err);
        }

        return this.players;
    }

    @action.bound
    async getPlayer(id) {
        // No endpoint provided from backend
        // Attempt filter, fallback on API call + filter
        const playerFromFilter = this.players.filter(
            (player) => player.id === id
        )[0];
        if (!playerFromFilter) {
            await this.getPlayers();
            this.activePlayer = this.players.filter(
                (player) => player.id === id
            )[0];
            return this.activePlayer;
        } else {
            this.activePlayer = playerFromFilter;
            return playerFromFilter;
        }
    }

    @action.bound
    async updatePlayer(player) {
        const { first_name, last_name, team, number, notes, position } = player;

        let error;
        await axios
            .put(
                `${this.rootStore.baseUrl}/portal/subjects/${player.id}?cl_events=true`,
                { first_name, last_name, team, number, notes, position },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        }
    }

    @action.bound
    setActiveCustomerSession() {
        let customer = this.activeCustomer;
        sessionStorage.setItem("activeCustomer", JSON.stringify(customer));
    }

    @action.bound
    getActiveCustomerSession() {
        if (this.activeCustomer.name !== undefined) {
            return this.activeCustomer;
        }

        let customer = sessionStorage.getItem("activeCustomer");
        return JSON.parse(customer);
    }

    moveToNewJob(jobId) {
        return this.getApi().updateCustomer(this.activeCustomer.id, {
            job_id: Number(jobId),
        });
    }
}
