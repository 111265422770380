// Packages
import React from "react";
import { Route, withRouter } from "react-router-dom";
import { Icon, NavBar } from "antd-mobile";
import MetaTags from "react-meta-tags";
import PreSignUp from "../../modules/presignup";
import Zoho from "../../modules/Zoho";

class FloatLayout extends React.Component {
    state = {
        logo: {
            src: "/assets/image.svg",
            height: "25px",
        },
    };
    getToken = () => {
        return localStorage.getItem("capturelife-token");
    };

    getAccountId() {
        if (this.props.path === "/register/:id") {
            let path = this.props.location.pathname.split("/");
            localStorage.setItem("accountId", path[2]);
            return path[2];
        } else {
            return localStorage.getItem("accountId");
        }
    }

    isPaymentPage() {
        let path = this.props.location.pathname;
        return path.search("register/pay") > 0;
    }

    loadLogo() {
        let accountId = Number(this.getAccountId());
        if (accountId && !this.isPaymentPage()) {
            this.setState({
                logo: { src: PreSignUp.getLogoUrl(accountId), height: "45px" },
            });
        }
    }

    useDefaultImg() {
        this.setState({
            logo: {
                src: "/assets/image.svg",
                height: "25px",
            },
        });
    }

    componentDidMount() {
        this.loadLogo();
        Zoho.removeZohoLauncher();
    }

    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(routeProps) => (
                    <div className="float-layout">
                        <MetaTags>
                            <title>Register For Action Photos</title>
                            <meta property="og:title" content="GFcrew APP" />
                            <meta
                                property="og:description"
                                content="Find your event. Register for action Photos. | GFcrew App"
                            />
                            <meta
                                property="og:image"
                                content="/FBOG_genericRegister.jpg"
                            />
                        </MetaTags>

                        <NavBar
                            mode="light"
                            icon={<Icon type="left" className="clickable" />}
                            onLeftClick={() => this.props.history.goBack()}
                        >
                            <img
                                id="user-logo"
                                src={this.state.logo.src}
                                height={this.state.logo.height}
                                alt="User Logo"
                                onError={this.useDefaultImg.bind(this)}
                            />
                        </NavBar>
                        <Component {...routeProps} />
                    </div>
                )}
            />
        );
    }
}

export default withRouter(FloatLayout);
