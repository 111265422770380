// Packages
import React from "react";
import { inject, observer } from "mobx-react";
// Third Party Components
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
// App Components
import AppLayout from "./layouts/AppLayout/AppLayout";
import FloatLayout from "./layouts/FloatLayout/FloatLayout";
import PublicLayout from "./layouts/PublicLayout/PublicLayout";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import AccountNew from "./pages/AccountNew/AccountNew";
import AccountEdit from "./pages/AccountEdit/AccountEdit";
import StripeSetup from "./pages/StripeSetup/StripeSetup";
import StripeBridge from "./pages/StripeBridge/StripeBridge";
import JobNew from "./pages/JobNew/JobNew";
import JobEdit from "./pages/JobEdit/JobEdit";
import Jobs from "./pages/Jobs/Jobs";
import JobDetail from "./pages/JobDetail/JobDetail";
import Moments from "./pages/Moments/Moments";
import CustomerNew from "./pages/CustomerNew/CustomerNew";
import CustomerEdit from "./pages/CustomerEdit/CustomerEdit";
import CustomerDetail from "./pages/CustomerDetail/CustomerDetail";
import PlayerEdit from "./pages/PlayerEdit/PlayerEdit";
import ChargeNew from "./pages/ChargeNew/ChargeNew";
import ChargeScan from "./pages/ChargeNew/ChargeScan";
import ListEvents from "./pages/PreSignUp/ListEvents";
import RegisterCustomer from "./pages/PreSignUp/RegisterCustomer";
import PreSignupPay from "./pages/PreSignUp/PreSignupPay";
import AppUpdates from "./pages/Updates/AppUpdates";
import Sales from "./pages/Sales/Sales";
// Styles & Images
import "./App.scss";

const App = inject("store")(
    observer(
        class App extends React.Component {
            render() {
                return (
                    <Router>
                        <StripeProvider
                            apiKey = {process.env.REACT_APP_STRIPE_KEY}
                        >
                            <Switch>
                                {/* Pre signup routes */}
                                <PublicLayout
                                    path="/register/:id"
                                    component={ListEvents}
                                    exact
                                />
                                <PublicLayout
                                    path="/register/customer/:id"
                                    component={RegisterCustomer}
                                    exact
                                />
                                <PublicLayout
                                    path="/register/pay/:id"
                                    component={PreSignupPay}
                                    exact
                                />
                                {/* End of pre signup routes */}
                                <AppLayout path="/" component={Jobs} exact />
                                <Route
                                    path="/sign-in"
                                    component={SignIn}
                                    exact
                                />
                                <Route
                                    path="/sign-up"
                                    component={SignUp}
                                    exact
                                />
                                <Route
                                    path="/setup-stripe"
                                    component={StripeSetup}
                                    exact
                                />
                                <AppLayout
                                    path="/stripe-settings"
                                    component={StripeBridge}
                                    exact
                                />
                                <Route
                                    path="/accounts/new"
                                    component={AccountNew}
                                    exact
                                />
                                <AppLayout
                                    path="/accounts/edit"
                                    component={AccountEdit}
                                    exact
                                />
                                <FloatLayout
                                    path="/jobs/new"
                                    component={JobNew}
                                    exact
                                />
                                <FloatLayout
                                    path="/jobs/:id/edit"
                                    component={JobEdit}
                                    exact
                                />
                                <AppLayout
                                    path="/jobs/:id"
                                    component={JobDetail}
                                    exact
                                />
                                <FloatLayout
                                    path="/moments/:id"
                                    component={Moments}
                                    exact
                                />
                                <FloatLayout
                                    path="/customers/new"
                                    component={CustomerNew}
                                    exact
                                />
                                <FloatLayout
                                    path="/customers/:id/edit"
                                    component={CustomerEdit}
                                    exact
                                />
                                <AppLayout
                                    path="/customers/:id"
                                    component={CustomerDetail}
                                    exact
                                />
                                <FloatLayout
                                    path="/players/:id/edit"
                                    component={PlayerEdit}
                                    exact
                                />
                                <FloatLayout
                                    path="/charges/new"
                                    component={ChargeNew}
                                    exact
                                />
                                <FloatLayout
                                    path="/charges/scan"
                                    component={ChargeScan}
                                    exact
                                />
                                <FloatLayout
                                    path="/updates"
                                    component={AppUpdates}
                                    exact
                                />
                                <AppLayout
                                    path="/sales"
                                    component={Sales}
                                    exact
                                />
                            </Switch>
                        </StripeProvider>
                    </Router>
                );
            }
        }
    )
);

export default App;
