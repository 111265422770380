// Packages
import React from "react";
import { action, observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { InputItem, Button, Toast, Icon } from "antd-mobile";
// Styles
import "./PlayerEdit.scss";

@inject("store")
@observer
class PlayerEdit extends React.Component {
    @observable
    loading = false;

    @observable
    player = {
        name: "",
        email: "",
        phone: "",
        position: "",
    };

    @computed get activePlayer() {
        return this.props.store.Customer.activePlayer;
    }

    @computed get playerId() {
        return Number(this.props.match.params.id);
    }

    @action.bound
    handleChange = (key, event) => {
        switch (key) {
            case "first_name":
                this.player.first_name = event;
                break;
            case "last_name":
                this.player.last_name = event;
                break;
            case "team":
                this.player.team = event;
                break;
            case "number":
                this.player.number = event;
                break;
            case "notes":
                this.player.notes = event;
                break;
            case "position":
                this.player.position = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    handleSubmit = async () => {
        this.loading = true;
        const resError = await this.props.store.Customer.updatePlayer(
            this.player
        );
        this.loading = false;

        if (resError) {
            Toast.fail(resError, 1.5);
        } else {
            Toast.success("Player updated", 1.5);
            this.props.history.push(`/customers/${this.player.customer_id}`);
        }
    };

    @action.bound
    guardAndGetPlayer() {
        if (!this.activeCustomer || !this.activeCustomer.id) {
            Toast.fail("Please select a customer to edit a player", 3);
            this.props.history.push("/");
        } else {
            this.loading = true;
            this.props.store.Customer.getPlayer(this.playerId).then(
                (player) => {
                    this.loading = false;
                    this.player = player;
                }
            );
        }
    }

    componentDidMount() {
        if (!this.activePlayer || this.activePlayer.id !== this.playerId) {
            this.guardAndGetPlayer();
        } else {
            this.player = this.activePlayer;
        }
    }

    render() {
        return (
            <div className="new-player-page">
                <div className="text-center">
                    <h2>Edit Player</h2>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <InputItem
                        clear
                        placeholder="First Name"
                        value={this.player.first_name}
                        onChange={(event) =>
                            this.handleChange("first_name", event)
                        }
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Last Name"
                        value={this.player.last_name}
                        onChange={(event) =>
                            this.handleChange("last_name", event)
                        }
                    >
                        <img src="/assets/user.svg" alt="user" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Team"
                        value={this.player.team}
                        onChange={(event) => this.handleChange("team", event)}
                    >
                        <img src="/assets/jersey.svg" alt="jersey" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Jersey #"
                        value={this.player.number}
                        onChange={(event) => this.handleChange("number", event)}
                    >
                        <img src="/assets/number.svg" alt="number" />
                    </InputItem>

                    <br />
                    <InputItem
                        clear
                        placeholder="Age / Div."
                        value={this.player.notes}
                        onChange={(event) => this.handleChange("notes", event)}
                    >
                        <img src="/assets/nametag.svg" alt="nametag" />
                    </InputItem>
                    <br />
                    <InputItem
                        clear
                        placeholder="Notes / Position"
                        value={this.player.position}
                        onChange={(event) =>
                            this.handleChange("position", event)
                        }
                    >
                        <img src="/assets/info.svg" alt="position" />
                    </InputItem>
                </form>

                <div className="flex-center">
                    <Button
                        type="primary"
                        onClick={this.handleSubmit}
                        className="gf-button"
                    >
                        {this.loading ? <Icon type="loading" /> : "SAVE"}
                    </Button>
                </div>
            </div>
        );
    }
}

export default PlayerEdit;
