// Packages
import React from "react";
// Components
import { NavBar } from "antd-mobile";
import PhotographerReport from "../../compoents/reports/PhotographerReport";

class Sales extends React.Component {
    render() {
        return (
            <div>
                <NavBar mode="light">
                    <img src="/assets/logo.png" alt="GF Crew Logo" />
                </NavBar>
                <PhotographerReport />
            </div>
        );
    }
}

export default Sales;
