import React from "react";
import { Modal, Toast } from "antd-mobile";

const alert = Modal.alert;

class PreRegisterLinkBtn extends React.Component {
    copyUrlToClipboard() {
        navigator.clipboard.writeText(this.getPreRegisterUrl()).then(() => {
            Toast.success("Copied Link", 1);
        });
    }

    visitPreRegPage() {
        window.open(this.getPreRegisterUrl());
    }

    getPreRegisterUrl() {
        return "https://register.gfcrew.com/" + this.props.account_id;
    }

    render() {
        return (
            <>
                {this.props.pre_signup && (
                    <a
                        href="##"
                        onClick={() =>
                            alert(
                                "Unique Pre-Registration Link",
                                <div>{this.getPreRegisterUrl()}</div>,
                                [
                                    {
                                        text: "Copy to Clipboard",
                                        onPress: () =>
                                            this.copyUrlToClipboard(),
                                    },
                                    {
                                        text: "Visit Page",
                                        onPress: () => this.visitPreRegPage(),
                                    },
                                    {
                                        text: "Close",
                                        style: "ghost",
                                    },
                                ]
                            )
                        }
                    >
                        {this.props.children ? (
                            this.props.children
                        ) : (
                            <img
                                height="19px"
                                src="/assets/register.svg"
                                alt="Pre Signup"
                                className="inline-icon"
                            />
                        )}
                    </a>
                )}
            </>
        );
    }
}
export default PreRegisterLinkBtn;
