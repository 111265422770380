import React from "react";
import { inject } from "mobx-react";
import { Tabs } from "antd-mobile";
import SalesSummary from "./SalesSummary";
import Profits from "./Profits";
import Loading from "../notifications/Loading";

@inject("store")
class PhotographerReport extends React.Component {
    state = {
        allJobs: [],
    };

    async componentDidMount() {
        this.setState({
            allJobs: await this.props.store.Job.getAllJobs(),
        });
    }

    render() {
        return (
            <div id="photographer-reports">
                <Loading loading={this.state.allJobs.length === 0} />
                {this.state.allJobs.length > 0 && (
                    <Tabs
                        tabs={[
                            { title: "Sales Summary" },
                            // { title: "Profits" },
                        ]}
                        onChange={(tab, index) => {}}
                        // onTabClick={(tab, index) => {}}
                        initialPage={0}
                    >
                        <div className="container">
                            <SalesSummary allJobs={this.state.allJobs} />
                        </div>
                        {/* <div className="container">
                            <Profits allJobs={this.state.allJobs} />
                        </div> */}
                    </Tabs>
                )}
            </div>
        );
    }
}
export default PhotographerReport;
