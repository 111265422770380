import { observable, action } from "mobx";
import axios from "axios";

export default class UserStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    // Observables
    @observable
    usersUrl = `${this.rootStore.baseUrl}/user?cl_events=true`;

    @observable
    user = {};

    // Actions
    @action.bound
    async signIn(email, password) {
        try {
            let res = await axios.post(
                `${this.rootStore.baseUrl}/user/login?cl_events=true`,
                {
                    email,
                    password,
                }
            );
            console.log(res.data);
            await Promise.resolve().then(function () {
                localStorage.setItem("capturelife-token", res.data.token);
                sessionStorage.setItem("capturelife-token", res.data.token);
            });
            this.user = res.data;
            this.setUserSession();
        } catch (err) {
            let error = err.response.data.error;
            if (
                error ===
                "crypto/bcrypt: hashedPassword is not the hash of the given password"
            ) {
                error = "Invalid user password";
            }
            return error;
        }
    }

    @action.bound
    async signUp(name, email, password, phone) {
        let error;
        const res = await axios
            .post(this.usersUrl, { name, email, password, phone })
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        } else {
            localStorage.setItem("capturelife-token", res.data.token);
            sessionStorage.setItem("capturelife-token", res.data.token);
            this.user = res.data;
            this.setUserSession();
        }
    }

    @action.bound
    async updateEmail(email) {
        console.log(email);
        let res = await axios.put(
            `${this.rootStore.baseUrl}/users/me`,
            {
                email: email,
            },
            { headers: this.rootStore.authHeaders }
        );

        this.user = res.data;
        this.setUserSession();
        return this.user;
    }

    @action.bound
    async updateUserPhone(phone) {
        try {
            const res = await axios.put(
                `${this.rootStore.baseUrl}/users/me`,
                {
                    phone: phone,
                },
                { headers: this.rootStore.authHeaders }
            );

            this.user = res.data;
            this.setUserSession();
            return this.user;
        } catch (error) {
            return error;
        }
    }

    @action.bound
    setUserSession() {
        let user = {
            email: this.user.email,
            phone: this.user.phone,
        };

        sessionStorage.setItem("user", JSON.stringify(user));
    }

    @action.bound
    getUserSession() {
        if (this.user.email) {
            return {
                email: this.user.email,
                phone: this.user.phone,
            };
        }

        let user = sessionStorage.getItem("user");

        if (user == "undefined" || user == null) {
            this.clearStorage();
            window.location.href = "/";
        }

        return JSON.parse(user);
    }

    @action.bound
    clearStorage() {
        this.user = {};
        localStorage.clear();
        sessionStorage.clear();
    }
}
