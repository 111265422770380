import React from "react";
import { inject } from "mobx-react";
import { List, Flex, Picker, Badge } from "antd-mobile";
import { toJS } from "mobx";
import Loading from "../notifications/Loading";
import PayPeriod from "../../modules/PayPeriod";

const Item = List.Item;
const STRIPE_STATUS_CREATED = 0;
const STRIPE_STATUS_PENDING = 1;
const STRIPE_STATUS_COMPLETE = 2;
const Year_FILTERS = [
    { label: "2018", value: 2018 },
    { label: "2019", value: 2019 },
    { label: "2020", value: 2020 },
    { label: "2021", value: 2021 },
    { label: "2022", value: 2022 },
];

@inject("store")
class Profits extends React.Component {
    state = {
        transfers: [],
        payPeriodsByYear: [],
        year: 2020,
    };

    filterByYear(val) {
        this.setState({
            payPeriodsByYear: [],
            year: val[0],
        });
        this.loadPayPeriodsByYear(val[0]);
    }

    // getJobsWithCustomers() {
    //     return this.props.allJobs.filter((job) => job.customer_count > 0);
    // }

    // getCustomersByJob(job) {
    //     return this.props.store.Customer.getCustomersByJobId(job.id);
    // }

    // async getCustomersThatHaveCharges(job) {
    //     let customersWithCharges = [];
    //     let customers = toJS(await this.getCustomersByJob(job)).data;
    //     customers.forEach((customer) => {
    //         if (customer.charged > 0) {
    //             customersWithCharges.push(customer);
    //         }
    //     });

    //     return customersWithCharges;
    // }

    getTransferStatus(status) {
        switch (status) {
            case STRIPE_STATUS_CREATED:
                return "Created";
            case STRIPE_STATUS_PENDING:
                return "Pending";
            case STRIPE_STATUS_COMPLETE:
                return "Completed";
            default:
                return "Created";
        }
    }

    // async getStripeTransfers() {
    //     let transfers = [];
    //     let customers = [];
    //     let customerStripeCharges = [];

    //     // Get all jobs that have customers
    //     let jobsWithCustomers = this.getJobsWithCustomers();

    //     // Merge all customers together that have charges
    //     for (const job of jobsWithCustomers) {
    //         customers = customers.concat(
    //             await this.getCustomersThatHaveCharges(job)
    //         );
    //     }

    //     // Get all customer stripe charge records
    //     for (const customer of customers) {
    //         let charge = toJS(
    //             await this.props.store.Charge.getCharge(customer.id)
    //         );
    //         customerStripeCharges = customerStripeCharges.concat(charge.data);
    //     }

    //     // Format stripe charge data
    //     for (const charge of customerStripeCharges) {
    //         let stripeTransfer = charge.stripe_transfer;

    //         if (stripeTransfer) {
    //             transfers.push({
    //                 id: stripeTransfer.stripe_id,
    //                 status: this.getTransferStatus(stripeTransfer.status_id),
    //                 amount: stripeTransfer.amount,
    //                 updated: stripeTransfer.updated_at,
    //                 created: stripeTransfer.created_at,
    //             });
    //         }
    //     }

    //     return transfers;
    // }

    async getStripeTransfersByAccount() {
        let transfers = [];
        let resp = await this.props.store.Charge.getAccountCharges(
            this.props.store.Account.account.id
        );

        for (const transfer of resp.data) {
            transfers.push({
                id: transfer.stripe_id,
                status: this.getTransferStatus(transfer.status_id),
                amount: transfer.amount,
                updated: transfer.updated_at,
                created: transfer.created_at,
            });
        }

        return transfers;
    }

    async loadPayPeriodsByYear(year) {
        /**
         * Old Way - a large amount of request - very slow
         */
        // let transfers = await this.getStripeTransfers();
        let transfers = await this.getStripeTransfersByAccount();
        let payPeriod = new PayPeriod(transfers);
        this.setState({
            payPeriodsByYear: payPeriod.getPayPeriodsForTheYear(year),
        });
    }

    async componentDidMount() {
        this.loadPayPeriodsByYear(new Date().getFullYear());
    }

    render() {
        let payPeriods = this.state.payPeriodsByYear;
        return (
            <div>
                <Loading loading={payPeriods.length === 0} />
                {payPeriods.length > 0 && (
                    <div>
                        <Flex>
                            <Flex.Item>
                                <Picker
                                    data={Year_FILTERS}
                                    cols={1}
                                    value={[this.state.year]}
                                    onOk={(val) => this.filterByYear(val)}
                                >
                                    <List.Item key="filter" arrow="horizontal">
                                        Filter
                                    </List.Item>
                                </Picker>
                            </Flex.Item>
                        </Flex>
                        <br />
                        <p>Pay Periods for Year: {this.state.year}</p>

                        <List className="my-list">
                            {payPeriods.map((payPeriod) => {
                                return (
                                    <Item
                                        key={payPeriod.date}
                                        extra={"$" + payPeriod.total / 100}
                                    >
                                        {payPeriod.date}
                                        <Badge
                                            text={payPeriod.status}
                                            style={{
                                                marginLeft: 12,
                                                padding: "0 3px",
                                                backgroundColor: "#fff",
                                                borderRadius: 2,
                                                color: "#E11F26",
                                                border: "1px solid #E11F26",
                                            }}
                                        />
                                    </Item>
                                );
                            })}
                        </List>
                        <p>
                            *please allow for 48hrs for funds to show up in your
                            bank
                        </p>
                    </div>
                )}
            </div>
        );
    }
}
export default Profits;
