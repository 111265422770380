import axios from "axios";
export default class GfcrewUpdates {
    static async getUpdatesFromServer() {
        try {
            let res = await axios.get(
                "https://gfcrew-updates.s3.us-east-2.amazonaws.com/updates.json",
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return res.data;
        } catch (err) {
            console.log(err);
            return [];
        }
    }
}
