// Packages
import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import Zoho from "../../modules/Zoho";
// Components
import { Icon, NavBar } from "antd-mobile";

class FloatLayout extends React.Component {
    getToken = () => {
        return localStorage.getItem("capturelife-token");
    };

    render() {
        const { component: Component, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(routeProps) =>
                    !this.getToken() ? (
                        <Redirect to="/sign-in" />
                    ) : (
                        <div className="float-layout">
                            <NavBar
                                mode="light"
                                icon={
                                    <Icon type="left" className="clickable" />
                                }
                                onLeftClick={() => this.props.history.goBack()}
                            >
                                <img
                                    src="/assets/logo.png"
                                    alt="GF Crew Logo"
                                />
                            </NavBar>
                            <Component {...routeProps} />
                        </div>
                    )
                }
            />
        );
    }
}

export default withRouter(FloatLayout);
