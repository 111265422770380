import { observable, action, $mobx } from "mobx";
import axios from "axios";
import uuidv1 from "uuid/v1";
import CaptureLifeApi from "../modules/CaptureLifeApi";
import { toJS } from "mobx";

const MAX_JOBS_PER_PAGE = 100;

export default class JobStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    // Observables
    @observable
    jobsUrl = `${this.rootStore.baseUrl}/portal/jobs?cl_events=true`;

    @observable
    jobs = [];

    @observable
    activeJob = {};

    getApi() {
        if (this.api instanceof CaptureLifeApi) {
            return this.api;
        } else {
            this.api = new CaptureLifeApi(this.rootStore.token);
            return this.api;
        }
    }

    formatDate(date) {
        return (
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2)
        );
    }

    // Actions
    @action.bound
    async createJob(newJob) {
        let {
            name,
            default_fee,
            start_date,
            end_date,
            description,
            pre_signup,
            max_registration,
        } = newJob;
        default_fee = Number(default_fee) * 100;
        max_registration = Number(max_registration);
        const event_id = uuidv1();

        start_date = this.formatDate(start_date);
        end_date = this.formatDate(end_date);

        console.log('create_job', start_date, end_date);

        let error;
        const res = await axios
            .post(
                this.jobsUrl,
                {
                    event_id,
                    name,
                    default_fee,
                    start_date,
                    end_date,
                    description,
                    pre_signup,
                    max_registration,
                },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        } else {
            this.activeJob = res.data;
            this.rootStore.Customer.customers = [];
            this.rootStore.Customer.players = [];
        }
    }

    @action.bound
    async getJobs(history, page) {
        if (!this.rootStore.Account.account.id)
            await this.rootStore.Account.getAccount(history);

        let headers;
        if (this.rootStore.User.user.token) {
            headers = {
                Authorization: `Token ${this.rootStore.User.user.token}`,
            };
        } else {
            headers = this.rootStore.authHeaders;
        }

        if (Number.isNaN(page) || page === undefined) {
            page = 1;
        }

        let error;
        const res = await axios
            .get(
                `${this.jobsUrl}&account_id=${this.rootStore.Account.account.id}&page=${page}`,
                { headers: headers }
            )
            .catch((err) => {
                error = err;
            });

        if (error) {
            return error;
        } else {
            this.jobs = res.data.sort(function (a, b) {
                return new Date(b.start_date) - new Date(a.start_date);
            });
            return this.jobs;
        }
    }

    @action.bound
    async getJob(id) {
        // No endpoint provided from backend
        // Attempt filter, fallback on API call + filter
        const jobFromFilter = this.jobs.filter((job) => job.id === id)[0];
        if (!jobFromFilter) {
            await this.getJobs();
            this.activeJob = this.jobs.filter((job) => job.id === id)[0];
            return this.activeJob;
        } else {
            this.activeJob = jobFromFilter;
            return jobFromFilter;
        }
    }

    @action.bound
    async updateJob(job) {
        let {
            name,
            default_fee,
            start_date,
            end_date,
            description,
            id,
            pre_signup,
            max_registration,
        } = job;
        default_fee = Number(default_fee) * 100;
        max_registration = Number(max_registration);

        if (start_date instanceof Date) {
            start_date = this.formatDate(start_date);
        }

        if (end_date instanceof Date) {
            end_date = this.formatDate(end_date);
        }

        let error;
        let response = await axios
            .put(
                `${this.rootStore.baseUrl}/portal/jobs/${id}?cl_events=true`,
                {
                    name,
                    default_fee,
                    start_date,
                    end_date,
                    description,
                    pre_signup,
                    max_registration,
                },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        }

        try {
            this.activeJob = response.data;
            this.getJobs();
        } catch (err) {
            console.log(err);
        }
    }

    @action.bound
    setActiveJobSession() {
        let job = this.activeJob;
        sessionStorage.setItem("activeJob", JSON.stringify(job));
    }

    @action.bound
    getActiveJobSession() {
        if (this.activeJob.id !== undefined) {
            this.setActiveJobSession();
            return this.activeJob;
        }

        let job = sessionStorage.getItem("activeJob");
        return JSON.parse(job);
    }

    deleteJob(jobId) {
        return this.getApi().deleteJob(jobId);
    }

    async getFutureJobsSortedByEndDate() {
        let jobs = toJS(await this.getJobs());

        jobs = jobs
            .filter((job) => job.id !== this.activeJob.id)
            .filter((job) => new Date() < new Date(job.end_date))
            .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

        return jobs;
    }

    async getAllJobs() {
        let currentJobs = [];
        let allJobs = [];
        let page = 1;
        do {
            currentJobs = toJS(await this.getJobs(null, page++));
            console.log(currentJobs);
            allJobs = allJobs.concat(currentJobs);
        } while (currentJobs.length >= MAX_JOBS_PER_PAGE);

        // Removing any timezone from dates.
        for (const job of allJobs) {

            // console.log(job);

            job.end_date = job.end_date == null ? null : job.end_date.substring(0, 10);
            job.start_date = job.start_date == null ? null : job.start_date.substring(0, 10);
        }
        console.log('alljobs');
        console.log(allJobs);
        return allJobs;
    }
}
