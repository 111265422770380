// Packages
import React from "react";
import { action, observable, computed, reaction } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import { withRouter } from "react-router-dom";
import ZapierHooks from "../../modules/ZapierHooks";
// Components
import { InputItem, Button, Icon, Toast, Modal, List } from "antd-mobile";
import { CardElement, injectStripe } from "react-stripe-elements";
// Styles
import "./ChargeNew.scss";

const cardFormStyles = () => {
    return {
        style: {
            base: {
                fontSize: "17px",
                fontFamily: "Roboto, sans-serif",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#c23d4b",
            },
        },
    };
};

@inject("store")
@observer
class ChargeForm extends React.Component {
    @observable
    loading = false;

    @observable
    ocrEnabled = process.env.REACT_APP_OCR_ENABLED;

    @observable
    ocrLoading = false;

    @observable
    amount = "";

    @observable
    cardImage = null;

    @observable
    ocrNumber = "";

    @observable
    showModal = false;

    @computed
    get customer() {
        return this.props.store.Customer.getActiveCustomerSession();
    }

    @computed
    get activeJob() {
        return this.props.store.Job.getActiveJobSession();
    }

    @computed
    get playerToCreate() {
        return this.props.store.Customer.playerToCreate;
    }

    @action.bound
    handleChange = (key, event) => {
        switch (key) {
            case "amount":
                this.amount = event;
                break;
            default:
                console.log("Unknown form value change");
        }
    };

    @action.bound
    handleSubmit = async () => {
        this.loading = true;

        if (
            (await this.props.store.Account.isAccountsStripeVerified()) ===
            false
        ) {
            Toast.fail(
                "You must setup your stripe account to take payments. Click on Payment tab below",
                2
            );
            this.loading = false;
            return;
        }

        const token = await this.props.stripe.createToken({
            type: "card",
            name: this.customer.name,
        });

        if (token.error) {
            this.loading = false;
            Toast.fail(token.error.message, 2);
            return;
        }

        const res = await this.props.store.Charge.createCharge(
            this.amount,
            token.token.id,
            this.customer.id
        );

        if (res.success === false) {
            this.loading = false;
            Toast.fail(res.message, 1.5);
        } else {
            await this.createPlayer();
            this.loading = false;
            this.recordRegisterWebHook(res.data.id);
            Toast.success("Payment processed!", 1.5);
            if (this.props.store.Job.activeJob.id) {
                this.props.history.push(
                    `/jobs/${this.props.store.Job.activeJob.id}`
                );
            } else {
                this.props.history.push("/");
            }
        }
    };

    async getWebhookData(chargeId) {
        let job = this.activeJob;
        let account = await this.props.store.Account.getAccount();
        let name = account.name + " ( " + account.id + " )";

        return {
            name: name,
            date: job.start_date,
            Price: "" + job.default_fee / 100,
            transactionID: "" + chargeId,
        };
    }

    async recordRegisterWebHook(chargeId) {
        ZapierHooks.recordRegisterWebHook(await this.getWebhookData(chargeId));
    }

    @action.bound
    createPlayer = async () => {
        if (this.playerToCreate)
            await this.props.store.Customer.createPlayer(this.playerToCreate);
        this.props.store.Customer.playerToCreate = null;
    };

    @action.bound
    handleImageSelected = async (event) => {
        this.ocrLoading = true;
        const dataUrl = await this.readFile(event.currentTarget.files[0]);
        const base64 = dataUrl.split(",", 2)[1];
        this.cardImage = base64;
    };

    readFile = (file) => {
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
            fr.onload = (x) => resolve(fr.result);
            fr.readAsDataURL(file);
        });
    };

    @action.bound
    handleModalClose = () => {
        const el = this.ocrInput;
        el.select();
        document.execCommand("copy");
        this.showModal = false;
        Toast.success("Copied to clipboard", 1.5);
    };

    @disposeOnUnmount
    cardImageReaction = reaction(
        () => this.cardImage,
        (image) => {
            this.props.store.Charge.processCardOcr(image).then((res) => {
                this.ocrLoading = false;
                if (res.err) {
                    Toast.fail(res.err, 2.5);
                } else {
                    this.ocrNumber = res.data.possibleCard;
                    this.showModal = true;
                }
            });
        }
    );

    isOverMinChargeAmount() {
        return parseInt(this.amount) < process.env.REACT_APP_MINIMUM_FEE;
    }

    async componentDidMount() {
        if (!this.customer.name) {
            Toast.fail("Please select a customer before taking payment", 2);
            this.props.history.push("/");
        }

        const job = this.activeJob;
        if (job && job.default_fee) {
            this.amount = job.default_fee / 100;
        }
    }

    render() {
        return (
            <div className="charge-form">
                <InputItem
                    clear
                    placeholder="Amount"
                    extra="dollar(s)"
                    type="number"
                    value={this.amount}
                    onChange={(event) => this.handleChange("amount", event)}
                >
                    <img src="/assets/dollar.svg" alt="dollar" />
                </InputItem>
                {this.amount.length > 0 && this.isOverMinChargeAmount() && (
                    <p className="validation-message">
                        *Amount must be at least $6
                    </p>
                )}
                <br />

                <form className="full-width-form">
                    <div className="stripe-input-container">
                        <CardElement {...cardFormStyles()} />
                    </div>
                    <br />
                </form>

                <div className="buttons">
                    <Button
                        type="warning"
                        onClick={this.handleSubmit}
                        disabled={this.isOverMinChargeAmount()}
                    >
                        {this.loading ? <Icon type="loading" /> : "CHARGE"}
                    </Button>
                </div>

                <Modal popup visible={this.showModal} animationType="slide-up">
                    <List
                        renderHeader={() => <div>Scanned Card Result</div>}
                        className="popup-list"
                    >
                        <List.Item key="ocr-res-input">
                            <input
                                readOnly
                                value={this.ocrNumber}
                                style={{
                                    height: "44px",
                                    background: "#fff",
                                    border: "1px solid #eaeaea",
                                    width: "100%",
                                    padding: "0 15px",
                                }}
                                ref={(input) => (this.ocrInput = input)}
                            />
                        </List.Item>

                        <List.Item key="modal-button">
                            <Button
                                type="primary"
                                onClick={this.handleModalClose}
                            >
                                <img
                                    src="/assets/clipboard-white.svg"
                                    alt="clipboard"
                                />
                                &nbsp; Copy and Close
                            </Button>
                        </List.Item>
                    </List>
                </Modal>
            </div>
        );
    }
}

export default withRouter(injectStripe(ChargeForm));
