import { observable, action } from "mobx";
import axios from "axios";
import CaptureLifeApi from "../modules/CaptureLifeApi";
import Settings from "../modules/settings";

export default class AccountStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    // Observables
    @observable
    accountsUrl = `${this.rootStore.baseUrl}/accounts?cl_events=true`;

    @observable
    account = {};

    getApi() {
        if (this.api instanceof CaptureLifeApi) {
            return this.api;
        } else {
            this.api = new CaptureLifeApi(this.rootStore.token);
            return this.api;
        }
    }

    async isAccountsStripeVerified() {
        let account =
            this.account.se_complete !== undefined
                ? this.account
                : await this.getAccount();

        return account.se_complete;
    }

    @action.bound
    async getAccount(history) {
        console.log(this.accountsUrl);
        let url = "https://api.capturelife.com/accounts?cl_events=true";
        let error;
        const res = await axios
            .get(url, { headers: this.rootStore.authHeaders })
            .catch((err) => {
                error = err.response.data.err;
            });


        if (error) {
            return error;
        } else {
            if (res.data.accounts.length > 0) {
                this.account = res.data.accounts[0]; // only supporting one account per user for now
                this.initAccountSettingsName();
                return this.account;
            } else {
                history.push("/accounts/new");
            }
        }
    }

    @action.bound
    async createAccount(name, website, logo_url) {
        try {
            let res = await this.getApi().createAccount(
                name,
                website,
                logo_url
            );
            this.account = res.data;
        } catch (err) {
            return err.response.data.error;
        }
    }

    @action.bound
    async updateAccount(name, website, id) {
        let error;
        const res = await axios
            .put(
                `${this.rootStore.baseUrl}/accounts/${id}?cl_events=true`,
                { name, website, logo_type: 1 },
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err.response.data.error;
            });

        if (error) {
            return error;
        } else {
            this.account = res.data;
        }
    }

    @action.bound
    async getLogoUploadUrl(fileType) {
        let error;
        const res = await axios
            .get(
                `${this.rootStore.baseUrl}/accounts/${this.account.id}/logo/upload?type=${fileType}&cl_events=true`,
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err;
            });

        if (error) {
            return null;
        } else {
            return res.data.url;
        }
    }

    @action.bound
    async uploadLogo(url, fileObj) {
        let error;
        await axios
            .put(url, fileObj.file, {
                headers: {
                    "Content-Type": fileObj.file.type,
                },
            })
            .catch((err) => {
                error = err.response.data.err;
            });

        if (error) {
            return error;
        }
    }

    @action.bound
    async getStripeConnectUrl() {
        if (!this.account.id) {
            await this.getAccount();
        }

        let error;
        const res = await axios
            .get(
                `${this.rootStore.baseUrl}/stripe/authorize?account_id=${this.account.id}&cl_events=true&progressive=true`,
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err;
            });

        if (error) {
            return null;
        } else {
            return res.data.stripe_connect_url;
        }
    }

    @action.bound
    async getStripeDashboardUrl() {
        if (!this.account.id) {
            await this.getAccount();
        }

        let error;
        const res = await axios
            .get(
                `${this.rootStore.baseUrl}/stripe/dashboard?account_id=${this.account.id}&cl_events=true`,
                { headers: this.rootStore.authHeaders }
            )
            .catch((err) => {
                error = err;
            });

        if (error) {
            return null;
        } else {
            return res.data.url;
        }
    }

    @action.bound
    async getAccountSettings(accountId) {
        /**
         * @todo add to session to cut down on the number of calls.
         */
        let api = this.getApi();
        return await api.getAccountSetting(accountId);
    }

    @action.bound
    async setAccountSettings(settings) {
        let api = this.getApi();
        return await api.setAccountSettings(settings, this.account.id);
    }

    /**
     * @todo move to login
     */
    async initAccountSettingsName() {
        try {
            let accountSettings = await this.getAccountSettings(
                this.account.id
            );
            if (
                accountSettings.name === null ||
                accountSettings.name === undefined
            ) {
                accountSettings.name = this.account.name;
                accountSettings.account_id = this.account.id;
                this.setAccountSettings(new Settings(accountSettings));
            }
        } catch (err) {
            console.log(err);
        }
    }
}
