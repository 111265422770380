// Packages
import React from "react";
import { computed, observable, action, reaction } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
// Components
import { Link } from "react-router-dom";
import { Card, Icon, NavBar, SearchBar, SegmentedControl } from "antd-mobile";
import ViewMomentsBtn from "../../compoents/buttons/ViewMomentsBtn";
import DeleteJobBtn from "../../compoents/buttons/DeleteJobBtn";
import JobCard from "../../compoents/cards/JobCard";
// Styles
import "./JobDetail.scss";
import { isThisQuarter } from "date-fns";

@inject("store")
@observer
class JobDetail extends React.Component {
    @observable loading = false;
    @observable searchTerm = "";
    @observable segment = "Customers";
    @observable isexpand = [];
    @observable isellipsis = [];
    @observable players = [];

    initFlag = false;

    @computed get job() {
        return this.props.store.Job.activeJob;
    }

    @computed get jobId() {
        return Number(this.props.match.params.id);
    }

    @computed get customers() {
        return this.props.store.Customer.customers.filter((customer) => {
            if (!customer.name) return true;

            const index = customer.name
                .toLowerCase()
                .search(this.searchTerm.toLowerCase());
            if (index !== -1) {
                return true;
            } else {
                return false;
            }
        });
    }

    // @computed get players() {
    //     return this.props.store.Customer.players.filter((player) => {
    //         if (!player.first_name && !player.last_name) return true;

    //         const name = `${player.first_name} ${player.last_name}`.toLowerCase();

    //         const index = name.search(this.searchTerm.toLowerCase());
    //         if (index !== -1) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     });
    // }

    @computed get segmentIndex() {
        if (this.segment === "Players") {
            return 1;
        } else {
            return 0;
        }
    }

    @action.bound
    getCustomers() {
        this.props.store.Customer.getCustomers().then((customers) => {
            this.loading = false;
        });
    }

    @action.bound
    getPlayers() {
        this.props.store.Customer.getPlayers("job").then((players) => {
            this.loading = false;
            for (var player in players) {
                this.isexpand.push(false);
                this.isellipsis.push(false);
            }

            this.players = this.props.store.Customer.players.filter((player) => {
                if (!player.first_name && !player.last_name) return true;

                const name = `${player.first_name} ${player.last_name}`.toLowerCase();

                const index = name.search(this.searchTerm.toLowerCase());
                if (index !== -1) {
                    return true;
                } else {
                    return false;
                }
            });

            // console.log(this.players);


            // for(var i = 0; i < this.players.length ; i ++) {
            //     var el = document.querySelector("#note_0");
            //     // console.log(this.players[i].position);
            //     el.innerHTML = this.players[i].position;
            //     this.isellipsis[i] = this.checkEllipsis(0);
            // }
        });
    }

    @action.bound
    initEllipsis() {
        for (var i = 0; i < this.players.length; i++) {
            var el = document.querySelector("#note_" + i);
            console.log(el);

            this.isellipsis[i] = this.checkEllipsis(i);
            console.log(this.isellipsis[i]);
        }
    }

    @action.bound
    search(event) {
        this.searchTerm = event;
    }

    @action.bound
    segmentSelection = (value) => {
        this.segment = value.split(" ")[0];
    };

    @disposeOnUnmount
    jobDetailReaction = reaction(
        () => this.props.store.Job.activeJob,
        (job) => {
            if (job.id) {
                this.getCustomers();
                this.getPlayers();
            }
        }
    );

    handleExpand(index) {

        var el = document.querySelector("#note_" + index);

        if(this.isexpand[index] == true){
            this.isexpand[index] = false;
            return;
        }

        if(this.checkEllipsis(index)){
            this.isexpand[index] = true;
        }
    }

    handleCollapse(index) {
        this.isexpand[index] = false;
    }

    checkEllipsis(index) {

        var el = document.querySelector("#note_" + index);
        if (!el) return 0;
        const noEllipsisWidth = el.offsetWidth;

        const ellipsisWidth = el.parentNode.offsetWidth;

        if (ellipsisWidth < noEllipsisWidth) {
            return true;
        } else {
            return false;
        }
        el.style.overflow = 'initial';
    }

    componentDidMount() {

        console.log('update 6');

        var el = document.querySelector("#note_0");
        console.log(el, 'sfdsdfsfdsfsdfsdfsdfsdfsdf');

        if (!this.job.id || this.job.id !== this.jobId) {
            // Attempt filter, fallback on API call + filter
            const jobFromFilter = this.props.store.Job.jobs.filter(
                (job) => job.id === this.jobId
            )[0];
            if (!jobFromFilter) {
                this.loading = true;
                this.props.store.Job.getJobs().then((jobs) => {
                    this.props.store.Job.activeJob = jobs.filter(
                        (job) => job.id === this.jobId
                    )[0];
                });
            } else {
                this.props.store.Job.activeJob = jobFromFilter;
                this.loading = true;
            }
        }

        if (this.props.store.Job.activeJob.id) {
            this.getPlayers();
        }

        // window.onresize = () => {
        //     var el = document.querySelector("#note_0");
        //     if (el) {
        //        this.initEllipsis();
        //        this.forceUpdate();
        //     }
        // };
        // Reset activeCustomer to unlock new customer form
        this.props.store.Customer.activeCustomer = {};
    }

    componentDidUpdate() {
        var el = document.querySelector("#note_0");
        if (el && !this.initFlag) {
            // this.initFlag = true;
            this.initEllipsis();
        }
    }

    render() {
        return (
            <>
                <NavBar
                    mode="light"
                    icon={<Icon type="left" className="clickable" />}
                    onLeftClick={() => this.props.history.push("/")}
                >
                    <img src="/assets/logo.png" alt="GF Crew Logo" />
                </NavBar>

                <div className="container">
                    {this.job.id && this.job.id === this.jobId && (
                        <JobCard job={this.job} edit={true} />
                    )}

                    <div className="flex-center">
                        <Link
                            to="/customers/new"
                            className="block-button am-button am-button-primary clickable"
                        >
                            ADD CUSTOMER OR PLAYER
                        </Link>
                    </div>
                    <div className="flex-center">
                        <ViewMomentsBtn jobId={this.jobId} />
                    </div>
                    <div className="flex-center">
                        <DeleteJobBtn jobId={this.jobId} />
                    </div>
                    {!this.loading && (
                        <>
                            <SegmentedControl
                                className="job-detail-segment-control"
                                selectedIndex={this.segmentIndex}
                                values={[
                                    `Customers (${this.customers.length})`,
                                    `Players (${this.players.length})`,
                                ]}
                                onValueChange={this.segmentSelection}
                            />

                            <SearchBar
                                placeholder="Search customers..."
                                value={this.searchTerm}
                                onChange={this.search}
                                onCancel={() => (this.searchTerm = "")}
                            />
                        </>
                    )}

                    {this.customers.length > 0 &&
                        !this.loading &&
                        this.segment !== "Players" &&
                        this.customers.map((customer) => {
                            return (
                                <Card className="job-card" key={customer.id}>
                                    <Card.Header
                                        title={
                                            customer.name
                                                ? customer.name
                                                : "No customer name found"
                                        }
                                        extra={
                                            <span className="primary-badge badge">
                                                ${customer.charged / 100}
                                            </span>
                                        }
                                    />
                                    <Card.Body>
                                        <img
                                            src="/assets/phone-warning.svg"
                                            className="inline-icon"
                                            alt="phone"
                                        />
                                        <span className="primary-text">
                                            {customer.phone
                                                ? customer.phone
                                                : "No phone found"}
                                        </span>
                                        <Link to={`/customers/${customer.id}`}>
                                            <Icon
                                                type="right"
                                                className="clickable job-detail-button primary-text"
                                            />
                                        </Link>
                                    </Card.Body>
                                    <Card.Footer
                                        content={
                                            <>
                                                <img
                                                    src="/assets/envelope-warning.svg"
                                                    className="inline-icon"
                                                    alt="envelope"
                                                />
                                                <span className="primary-text">
                                                    {" "}
                                                    {customer.email
                                                        ? customer.email
                                                        : "No email found"}
                                                </span>
                                            </>
                                        }
                                        extra={customer.position}
                                    />
                                </Card>
                            );
                        })}

                    {this.players.length > 0 &&
                        !this.loading &&
                        this.segment === "Players" &&
                        this.players.map((player, i) => {
                            return (
                                <Card className="job-card" key={player.id}>
                                    {this.isexpand[i]}
                                    <Card.Header
                                        title={`${player.first_name} ${player.last_name}`}
                                        extra={
                                            <Link
                                                to={`/players/${player.id}/edit`}
                                                onClick={() =>
                                                    (this.props.store.Customer.activePlayer = player)
                                                }
                                            >
                                                <img
                                                    src="/assets/edit-button.svg"
                                                    className="inline-icon"
                                                    alt="edit button"
                                                />
                                                <span className="primary-text">
                                                    Edit
                                                </span>
                                            </Link>
                                        }
                                    />
                                    <Card.Body>
                                        <span className="primary-text">
                                            {player.team
                                                ? player.team
                                                : "No team found"}
                                        </span>
                                    </Card.Body>
                                    <Card.Footer
                                        content={
                                            <>
                                                <img
                                                    src="/assets/number-warning.svg"
                                                    className="inline-icon"
                                                    alt="number"
                                                />
                                                <span className="primary-text">
                                                    {" "}
                                                    {player.number
                                                        ? player.number
                                                        : "No number found"}
                                                </span>
                                                <br />
                                                <img
                                                    src="/assets/user-warning.svg"
                                                    className="inline-icon"
                                                    alt="nametag"
                                                />

                                                <span className={this.isexpand[i] ? "primary-text player-note-expanded" : "primary-text player-note"}
                                                    id={"note_" + i}
                                                    onClick={() => {
                                                        this.handleExpand(i, this);
                                                    }}>
                                                    {player.position
                                                        ? player.position
                                                        : ""}
                                                    { this.isexpand[i] ? 
                                                    <img src = "/assets/up-arrow-blue1.png" className = "uparrow"/> : ''
                                                    }
                                                </span>
                                            </>
                                        }
                                        extra={
                                            <>
                                                {
                                                    this.isellipsis[i] ?
                                                        <span className="plusImg"><img
                                                            src="/assets/plus.png"
                                                            className="inline-icon"
                                                            alt="nametag"
                                                            onClick = {() => {
                                                                this.handleExpand(i);
                                                            }}
                                                        />
                                                        </span>
                                                        : ''
                                                }                                                
                                                {/* { this.isexpand[i] ? 
                                                        <img src = "/assets/up-arrow-blue1.png" className = "uparrow"
                                                        onClick={() => {
                                                            this.handleExpand(i, this);
                                                        }}/> : ''
                                                } */}
                                                <img
                                                    src="/assets/nametag-warning.svg"
                                                    className="inline-icon"
                                                    alt="nametag"
                                                />
                                                <span className="primary-text">
                                                    {player.notes
                                                        ? player.notes
                                                        : "No division found"}
                                                </span>
                                            </>
                                        }
                                    />
                                </Card>
                            );
                        })}

                    {this.loading && <Icon type="loading" />}
                </div>
            </>
        );
    }
}

export default JobDetail;
